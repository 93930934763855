import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type FileUploadState = {
  isFileUpload: boolean;
};

const initialState: FileUploadState = {
  isFileUpload: false,
};

export const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    setFileUpload: (state, action: PayloadAction<boolean>) => {
      state.isFileUpload = action.payload;
    },
    toggleFileUpload: (state) => {
      state.isFileUpload = !state.isFileUpload;
    },
  },
});

export const { toggleFileUpload ,setFileUpload} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
