import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '../Dropdown';
import { useSelector } from 'react-redux';
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { updateUserAccess } from '../../services/apiService';
const DataTable = ({ userdata }) => {
    const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [revokedUsers, setRevokedUsers] = useState([]);
  const [users, setUsers] = useState(userdata);
  const [currentData, setCurrentData] = useState([]);

  interface Item {
    id: number;
    name: string;
    email: string;
    phone: string;
    country: string;
    interest_Rate: string;
    register_date: string;
  }

  useEffect(() => {
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    setCurrentData(users.slice(indexOfFirstData, indexOfLastData));
  }, [users, currentPage]);

  const totalCustomers = users.length;
  const dataPerPage = 10;
  const pageCount = Math.ceil(totalCustomers / dataPerPage);

  let paginationNumbers = [];
  for (let i = 1; i <= pageCount; i++) {
    paginationNumbers.push(i);
  }

  if (pageCount > 5) {
    paginationNumbers.splice(3, pageCount - 5, '...' as any);
    paginationNumbers.splice(pageCount - 2, 1);
  }

  // Search functionality
  const handleInputChange = (e:any) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm.trim()) {
      const filterItems = users.filter((value) =>
        value.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filterItems);
    } else {
      setFilteredItems([]);
    }
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage:number) => {
    setCurrentPage(selectedPage);
  };

  const handleRevokeAccess = async (userId:number) => {
    const updatedUser = await updateUserAccess(userId, false);
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === userId ? updatedUser : user))
    );
  };

  const handleGrantAccess = async (userId:number) => {
    const updatedUser = await updateUserAccess(userId, true);
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === userId ? updatedUser : user))
    );
  };

  const displayedData = search ? filteredItems : currentData;
  const isRtl = useSelector((state) => state.themeConfig.rtlClass) === 'rtl';

  const formatDate = (isoString:Date) => {
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  };
    return (
        <div className="panel mt-6">
            <h5 className="font-semibold text-lg dark:text-white-light mb-5">User Data</h5>
            <div className=' w-full'>
                        <form className="mx-auto w-full mb-5 relative">
                            <div className="">
                                <input
                                    type="text"
                                    value={search}
                                    placeholder="Search user by name..."
                                    className="form-input shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] bg-white placeholder:text-white-light rounded-full h-11 placeholder:tracking-wider ltr:pr-11 rtl:pl-11"
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="btn btn-primary absolute ltr:right-1 rtl:left-1 inset-y-0 m-auto rounded-full w-10 h-10 p-0 flex items-center justify-center">
                                  <BsSearch className='text-[18px]' />
                                </button>
                            </div>
                        </form>
                        <div className="table-responsive mb-5 rounded-md">
                            <table className="table-hover bg-black rounded-md">
                        <thead>
                            <tr>
                                <th className='font-semibold text-white-light'>#</th>
                                <th className='font-semibold text-white-light'>User</th>
                                <th className='font-semibold text-white-light'>Email</th>
                                <th className='font-semibold text-white-light'>Phone</th>
                                <th className='font-semibold text-white-light'>Register Date</th>
                                <th className='font-semibold text-white-light'>Access</th>
                                <th className='font-bold text-white-light text-center'>Action</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                    {(displayedData).map((value, index) => (
                        <tr key={index} className={`cursor-pointer`}>
                            <td className=' text-info text-base'>
                                <div className="whitespace-nowrap">{index+1}</div>
                            </td>
                            <td className=' text-base'>{value.name}</td>
                            <td className='text-base text-info'>{value.email}</td>
                            <td className='text-base'>{value.phone}</td>
                            <td className='text-base'>{formatDate(value.registerDate)}</td>
                            <td className={`text-base ${value.disabled ? 'text-green-500' : 'text-red-500'}`}>
                                {value.disabled ? 'Granted' : 'Denied'}
                            </td>
                            <td className='flex justify-center'>
                                <div className="dropdown">
                                    <Dropdown
                                        placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
                                        btnClassName="btn btn-outline-primary dropdown-toggle"
                                        button={
                                            <>
                                                Action
                                                <span>
                                                    <RiArrowDropDownLine />

                                                </span>
                                            </>
                                        }
                                    >
                                        <ul className="!min-w-[70px] w-[10vw]">
                                            <li>
                                                <button type="button" onClick={()=>handleGrantAccess(value._id)}>Grant Access</button>
                                            </li>
                                            <li>
                                                <button type="button" onClick={()=> handleRevokeAccess(value._id)}>Revoke Access</button>
                                            </li>
                                        </ul>
                                    </Dropdown>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
                    </table>
                            <ul className="flex items-center justify-center  space-x-1 rtl:space-x-reverse m-auto border border-gray-800 p-4 ">
                                <li>
                                    <button
                                        type="button"
                                        className="flex justify-center font-semibold mr-2 px-2.5 py-2.5 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-primary dark:hover:bg-primary dark:bg-black border border-primary cursor-pointer"
                                        disabled={currentPage === 1}
                                        onClick={()=>setCurrentPage(currentPage-1)}
                                    >
                                        <RiArrowLeftDoubleFill />
                                    </button>
                                </li>
                                 {paginationNumbers.map((pageNumber, index) => {
                                return (
                                    <li key={index}>
                                        <button
                                            type="button"
                                            className={`flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-${currentPage === pageNumber ? 'primary' : ''} hover:text-white  hover:bg-primary dark:text-white dark:hover:bg-primary dark:bg-primary cursor-pointer`}
                                            onClick={() => handlePageChange(pageNumber)}>
                                            {pageNumber}
                                        </button>
                                    </li>
                                );
                                 })}
                                <li>
                                    <button
                                        type="button"
                                        className="flex justify-center font-semibold ml-2 px-2.5 py-2.5 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-primary dark:hover:bg-primary dark:bg-black border border-primary active:bg-primary cursor-pointer"
                                        onClick={()=>setCurrentPage(currentPage+1)}
                                    >
                                        <RiArrowRightDoubleFill />
                                    </button>
                                </li>    
                            </ul>
                        </div>
                    </div>
        </div>
    );
};

export default DataTable;
