const styleDiv = {
    display: 'flex',
    flecDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
};

const MapLegend = () => {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: '100px',
                right: '25px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '20px',
                borderRadius: '5px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                zIndex: 2000,
            }}
        >
            <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px' }}>
                <h3>Customer clusters</h3>
            </div>
            <div style={styleDiv}>
                <span
                    style={{
                        backgroundColor: '#ff0200',
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        marginRight: '5px',
                    }}
                ></span>
                High density
            </div>

            <div style={styleDiv}>
                <span
                    style={{
                        backgroundColor: '#8CF288',
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        marginRight: '5px',
                    }}
                ></span>
                Medium density
            </div>
            <div style={styleDiv}>
                <span
                    style={{
                        backgroundColor: '#B4BFE5',
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        marginRight: '5px',
                    }}
                ></span>
                Low density
            </div>
        </div>
    );
};

export default MapLegend;
