import { FC, useState, useEffect, useCallback } from 'react';
import { checkTraining } from '../../services/apiService';

interface ProgressProps {
    fileInfo: FileInfo | null;
    trainingInitiate: () => void;
    onTrainingComplete: () => void;
    companyID: string;
}

interface FileInfo {
    serverID: string;
    filename: string;
    headers: any;
    fileType: string;
    uploadedAt: string;
    id: string;
    fileTrained: String;
    originalName: String;
    fileUploadedToServer: String;
    fileTableReference: String;
    fileTrainingComplete: boolean;
}

const Progress: FC<ProgressProps> = ({ fileInfo, trainingInitiate, companyID, onTrainingComplete }) => {
    const [trainingComplete, setTrainingComplete] = useState<boolean>(false);
    const [trainingStatus, setTrainingStatus] = useState('Checking status');

    const checkAPITraining = useCallback(
        async (file: FileInfo) => {
            const reqObject = {
                fileID: file.id,
                companyID: companyID,
                tableName: file.fileTableReference,
            };

            try {
                const initiateResponse = await checkTraining(reqObject);
                return initiateResponse;
            } catch (error) {
                console.error('Error checking training status:', error);
                return false;
            }
        },
        [companyID]
    );

    const checkRedundantTraining = useCallback(async () => {
        setTrainingStatus('Checking...');
        if (fileInfo?.fileUploadedToServer) {
            if (fileInfo.fileTrained) {
                if (fileInfo.fileTrainingComplete) {
                    setTrainingStatus('Training Complete!');
                    setTrainingComplete(true);
                } else {
                    const trainingStatusResult = await checkAPITraining(fileInfo);

                    if (trainingStatusResult) {
                        setTrainingStatus('Training Complete!');
                        setTrainingComplete(true);
                        onTrainingComplete();
                    } else {
                        setTrainingStatus('Training ongoing... Rechecking...');
                        setTimeout(() => {
                            checkRedundantTraining();
                        }, 10000);
                    }
                }
            }
        }
    }, [fileInfo, checkAPITraining, onTrainingComplete]);

    useEffect(() => {
        setTrainingComplete(fileInfo?.fileTrainingComplete || false);
        checkRedundantTraining();
    }, [fileInfo, checkRedundantTraining]);

    return (
        <div className="mb-5">
            <div className="max-w-[900px] mx-auto">
                <div className="flex">
                    <p className="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5">#1</p>
                    <div className="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-primary before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-primary after:rounded-full"></div>
                    <div className="p-2.5 self-center ltr:ml-2.5 rtl:ltr:mr-2.5 rtl:ml-2.5">
                        <p className="text-[#3b3f5c] dark:text-white-light font-semibold text-[13px]">Upload File</p>
                        <p className="text-white-dark text-xs font-bold self-center min-w-[100px] max-w-[100px]">25 mins ago</p>
                    </div>
                </div>
                <div className={fileInfo ? '' : 'hidden'}>
                    <div className="flex">
                        <p className="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5">#2</p>
                        <div className="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-secondary before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-secondary after:rounded-full"></div>
                        <div className="p-2.5 self-center ltr:ml-2.5 rtl:ltr:mr-2.5 rtl:ml-2.5">
                            <p className="text-[#3b3f5c] dark:text-white-light font-semibold text-[13px]">Describe Variables</p>
                            <p className="text-white-dark text-xs font-bold self-center min-w-[100px] max-w-[100px]">2 hrs ago</p>
                        </div>
                    </div>

                    <div className={fileInfo?.fileUploadedToServer ? '' : 'hidden'}>
                        <div className="flex">
                            <p className="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5">#3</p>
                            <div className="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-warning before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-warning after:rounded-full"></div>
                            <div className="p-2.5 self-center ltr:ml-2.5 rtl:ltr:mr-2.5 rtl:ml-2.5">
                                <p className="text-[#3b3f5c] dark:text-white-light font-semibold text-[13px]">Training initiation</p>
                                <p className="text-white-dark text-xs font-bold self-center min-w-[100px] max-w-[100px]">9 hrs ago</p>
                                <div className={fileInfo?.fileTrained ? 'hidden' : 'w-full flex justify-center mt-4'}>
                                    <button onClick={trainingInitiate} className="px-2 py-1 rounded bg-primary text-white font-bold hover:bg-secondary">
                                        Train file
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={fileInfo?.fileTrained ? 'flex' : 'hidden'}>
                            <p className="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5">#4</p>
                            <div className="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-warning before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-warning after:rounded-full"></div>
                            <div className="p-2.5 self-center ltr:ml-2.5 rtl:ltr:mr-2.5 rtl:ml-2.5">
                                <p className="text-[#3b3f5c] dark:text-white-light font-semibold text-[13px]">Training Initiated</p>
                                <p className="text-white-dark text-xs font-bold self-center min-w-[100px] max-w-[100px]">9 hrs ago</p>
                                <div className={fileInfo?.fileTrained ? 'w-full flex flex-col mt-4' : 'hidden'}>
                                    <span className="font-bold text-xs">Status:</span>
                                    <span className="text-xs mt-2">{trainingStatus}</span>
                                </div>
                            </div>
                        </div>
                        <div className={fileInfo?.fileTrainingComplete ? 'flex' : 'hidden'}>
                            <p className="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5">#5</p>
                            <div className="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-info before:rounded-full"></div>
                            <div className="p-2.5 self-center ltr:ml-2.5 rtl:ltr:mr-2.5 rtl:ml-2.5">
                                <p className="text-[#3b3f5c] dark:text-white-light font-semibold text-[13px]">Training Complete</p>
                                <p className="text-white-dark text-xs font-bold self-center min-w-[100px] max-w-[100px]">8 hrs ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Progress;
