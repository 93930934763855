import React, { useRef,useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'; // Import FilePond styles
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { uploadHistoricalFile } from '../../services/apiService';
import { User } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import IconCircleCheck from '../Icon/IconCircleCheck';
import IconPlusCircle from '../../components/Icon/IconPlusCircle';
import IconMinusCircle from '../../components/Icon/IconMinusCircle';
import AnimateHeight from 'react-animate-height';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);


const UploadEntity = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    let BASE_URL = import.meta.env.PROD ? import.meta.env.VITE_API_URL_PROD : import.meta.env.VITE_API_URL;
    
    const user = useSelector((state: any) => state.user);
    
    const pondRef = useRef<FilePond>();

    // Function to handle file upload
    const handleInit = () => {
        console.log('FilePond instance has initialized');
    };

    // Function to handle file upload progress
    const handleProgress = (file: any, progress: any) => {
        console.log('File upload progress:', progress);
    };

    // Function to handle file upload success
    const handleFileSuccess = (file: any) => {
        const serverID = file.serverId;
        console.log('File upload success:', file);
        console.log('get server id: ', serverID);
    };

    // Function to handle file upload error
    const handleFileError = (file: any, error: any) => {
        console.error('File upload error:', error);
    };

    // Function to handle file upload
    const handleFileProcess = async (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any, abort: any) => {
        console.log('file: ', file);

        try {
            const historicalData = {
                file: file,
            };
            await uploadHistoricalFile(historicalData);
            load(); // Signal that the chunk has been uploaded successfully
        } catch (err) {
            error('Error uploading chunk');
        }
    };

    const onAddFile = async (error: any, file: any) => {
        console.log('add file: ', file);
    };
    const data = [
        {
            value: 'Executive Summary',
            description:'Overview of the business, its mission, and key objectives.'
        },
        {
            value: 'Products or Services',
            description:'Description of the products or services offered.'
        },
        {
            value: 'Market Analysis',
            description:'Analysis of the industry, market size, target market, and competition.'
        },
        {
            value: 'Marketing and Sales Strategy',
            description:'Plan for marketing and sales efforts.'
        },
        {
            value: 'Financial Projections',
            description:'Forecasted income statements, cash flow statements, and balance sheets.'
        },
        {
            value: 'Organization and Management',
            description:'Analysis of the industry, market size, target market, and competition.'
        },
        {
            value: 'Appendices',
            description:'Additional information such as resumes of the management team, legal documents, and product photos.'
        },
    ]

    return (
        <div className='flex flex-col gap-5'>
            <h1 className='text-primary text-[18px] mt-4'>Upload a Business Plan or Proposal</h1>
            <FilePond
                chunkUploads={true}
                chunkForce={true}
                onaddfile={(error, file) => onAddFile(error, file)}
                maxFiles={1}
                chunkSize={2000000}
                server={{
                    url:`${BASE_URL}/upload/entity`,
                    headers: {
                        user: user.user.id,
                    },
                }}
                oninit={() => handleInit()}
                onprocessfileprogress={handleProgress}
                onprocessfile={(error, file) => (error ? handleFileError(file, error) : handleFileSuccess(file))}
                acceptedFileTypes={['application/pdf']}
            />
            <p className='text-gray-200 text-base'>For better analysis and accurate results the submitted business plan should include atleast all the elements below:</p>
            <div className="rounded-md bg-black w-[50vw]">

                <div className="divide-y divide-white-light px-6 pb-4.5 dark:divide-dark">
                    {
                            <div>
                                {data.map((item, index) => (
                                    <div key={index}>
                                        <div
                                            className={`flex cursor-pointer items-center justify-between gap-10 px-2.5 py-2 text-base font-semibold hover:text-primary dark:text-white dark:hover:text-primary border-b border-gray-500
            ${activeIndex === index ? 'bg-primary-light dark:bg-[#1B2E4B] !text-primary' : ''}`}
                                            onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                                        >
                                            <span>{item.value}</span>
                                            {activeIndex !== index ? (
                                                <span className="shrink-0">
                                                    <IconPlusCircle duotone={false} />
                                                </span>
                                            ) : (
                                                <span className="shrink-0">
                                                    <IconMinusCircle fill={true} />
                                                </span>
                                            )}
                                        </div>
                                        <AnimateHeight duration={300} height={activeIndex === index ? 'auto' : 0}>
                                            <div className="px-1 py-3 font-semibold text-gray-300">
                                                <p>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                ))}
                            </div>
                            
                    }

                </div>
            </div>
            </div>
    );
};

export default UploadEntity;
