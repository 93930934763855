import { useEffect, useState } from 'react'
import logo from '../../../assets/images/download.png'
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, NavLink, useLocation } from 'react-router-dom';
const UserProfile = () => {
  const user = useSelector((state: any) => state.user);
  console.log(user)

  const initialUserData = {
    name: "",
    email:"", 
    organisation: "Aice Africa",
    country: "",
    address: "",
    location: "",
    phone: "",
    website: "",
  };
  const [userData, setUserData] = useState(initialUserData);
  const [formData, setFormData] = useState(initialUserData);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
        
  };
  const isDataChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(userData);
  };
  const handleSave = () => {
    if (isDataChanged()) {
      setUserData(formData);
    }
  };
  const previousPage = () => { 

  }

  return (
    <div className='p-5'>
      <p className='py-5 tracking-wide text-white-light text-[16px] flex items-center gap-3'> <Link to='/predict'><BsArrowLeft className=' cursor-pointer hover:text-lg' onClick={previousPage} /></Link> User Profile</p>
      <form className="border border-[#ebedf2] dark:border-[#191e3a] rounded-md mb-5 bg-white dark:bg-black p-5">
        <div className="flex flex-col justify-center sm:flex-row">
          <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full sm:w-2/12 mb-5 flex flex-col gap-5 items-center">
            <img src={logo} alt="img" className="w-40 h-40  rounded-full object-contain mx-auto" />
            <p className='text-xl'>Aice Africa</p>
          </div>
          <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <label htmlFor="name">Full Name</label>
              <input
                id="name"
                type="text"
                value={formData.name}
                placeholder={userData.name}
                className="form-input"
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={formData.email}
                placeholder={userData.email}
                className="form-input"
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div>
              <label htmlFor="organisation">Organisation</label>
              <input
                id="organisation"
                type="text"
                value={formData.organisation}
                placeholder={userData.organisation}
                className="form-input"
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div>
              <label htmlFor="address">Address</label>
              <input
                id="address"
                type="text"
                value={formData.address}
                placeholder={userData.address}
                className="form-input"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="location">Location</label>
              <input
                id="location"
                type="text"
                value={formData.location}
                placeholder={userData.location}
                className="form-input"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                id="phone"
                type="text"
                value={formData.phone}
                placeholder={userData.phone}
                className="form-input"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="website">Website</label>
              <input
                id="website"
                type="text"
                value={formData.website}
                placeholder={userData.website}
                className="form-input"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="inline-flex cursor-pointer">
                <input type="checkbox" className="form-checkbox" />
                <span className="text-white-dark relative checked:bg-none">Make this my default address</span>
              </label>
            </div>
            <div className="sm:col-span-2 mt-3">
              <button type="button" className="btn btn-primary" disabled={!isDataChanged()} onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
    
  )
}

export default UserProfile