import React, { useState, useEffect } from 'react';
import IconSearch from '../Icon/IconSearch';
import IconHorizontalDots from '../Icon/IconHorizontalDots';

interface Profile {
    thumb: String;
    name: String;
    email: String;
    creditScore: number;
    totalAccounts: number;
    totalTransactions: number;
    regularIncomeAmount: number;
    averageExpenditure: number;
    totalCreditRequests: number;
    defaultRate: number;
    averageAccountBalance: number;
    profileID: String;
    profileIdentifier: String;
    identifierType: String;
}
interface CreditProfileSearchComponents {
    onProfileSelect: (profile: Profile) => void;
    CustomerData: any;

}

const CreditProfileSearch: React.FC<CreditProfileSearchComponents> = ({ onProfileSelect,CustomerData }) => {
    const items = CustomerData;

    const getCreditClass = (score: number) => {
        if (score < 500) {
            return 'badge badge-outline-danger';
        } else if (score > 500 && score < 600) {
            return 'badge badge-outline-warning';
        } else {
            return 'badge badge-outline-primary';
        }
    };

    const [search, setSearch] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<any>(items);

    // useEffect(() => {
    //     setFilteredItems(() => {
    //         return items.filter((item) => {
    //             return item.id_number.includes(search) 
    //         });
    //     });
    // }, [search]);

    const onProfileSelected = (profile: Profile) => {
        onProfileSelect(profile);
    };

    return (
        <div className="mb-5 space-y-5">
            <form className="mx-auto w-full sm:w-1/2 mb-5">
                <div className="relative">
                    <input
                        type="text"
                        value={search}
                        placeholder="Search Attendees..."
                        className="form-input shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] bg-white rounded-full h-11 placeholder:tracking-wider ltr:pr-11 rtl:pl-11"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="button" className="btn btn-primary absolute ltr:right-1 rtl:left-1 inset-y-0 m-auto rounded-full w-9 h-9 p-0 flex items-center justify-center">
                        <IconSearch className="mx-auto" />
                    </button>
                </div>
            </form>
            <div className="p-4 border border-white-dark/20 rounded-lg space-y-4 overflow-x-auto w-full block max-h-[500px] customers_container">
                {items.map((item: any, index: any) => {
                    return (
                        <div
                            key={index}
                            onClick={() => onProfileSelected(item)}
                            className="bg-white dark:bg-primary-dark-light/5 rounded-xl shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] p-3 flex items-center justify-between
                                         text-gray-500 font-semibold max-w-[625px] hover:text-primary transition-all duration-300 hover:scale-[1.01] cursor-pointer"
                        >
                            {/* <div className="user-profile">
                                <img src={`/assets/images/${item.thumb}`} alt="img" className="w-8 h-8 rounded-md object-cover" />
                            </div> */}
                            <div className='text-gray-300 text-base w-[100px]'>{item.id_number}</div>
                            <div className={`badge ${getCreditClass(item.credit_score)} border-2 border-dashed badge cursor-pointer`}>{item.credit_score}</div>
                            <div className="cursor-pointer">
                                <IconHorizontalDots className="w-6 h-6 opacity-70" />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CreditProfileSearch;
