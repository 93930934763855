import React, { useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import headers from '../../../data/headers.json';
import ProfileDetails from './ProfileDetails';
import { getCustomerById } from '../../../services/apiService';
const SingleUpload = () => {
  const user = useSelector((state: any) => state.user);
  const [isProfileView, setIsProfileView] = useState(false);
  const [customer, setCustomer] = useState<any>({})
  
  // Define FormData type
  type FormData = {
    [key: string]: string;
  };

  // Initialize initialFormData based on headers
  const initialFormData: FormData = {};
  headers.forEach((header: string) => {
    initialFormData[header] = '';
  });

  // State variables for form data
  const [formData, setFormData] = useState<FormData>(initialFormData);

  // Handle input change for each input field
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Check if all form fields are filled
  const areAllFieldsFilled = () => {
    for (const key in formData) {
      if (formData[key].trim() === '') {
        return false;
      }
    }
    return true;
  };
  const fetchCustomer = async () => { 
  try {
    const customer = await getCustomerById(formData['ID NUMBER']);
    setCustomer(customer);
    console.log(customer)
    setIsProfileView(true);
  } catch (error) {
    setCustomer({});
    setIsProfileView(true);
    console.error('Error fetching customer:', error);
  }

  }

  // Handle save button click
  const handleSave = () => {
    if (areAllFieldsFilled()) {
      console.log(formData); 
      setFormData(initialFormData); 
      fetchCustomer()
    }
  };
  const handleCloseProfile = () => {
    setIsProfileView(false);
  };
  const selectedUser = {
        "id": 5,
        "name": "Maria Rossi",
        "country": "Italy",
        "email": "maria.rossi@example.it",
        "phone": "+39-06-5555-0160",
        "credit_score": 300,
        "cluster":"D",
                "contributingFactors":{
          "positive":[
            "Timely Payments",
            "Long Credit History",
            "Debt Repayment"
          ],
          "negative":[
            "High Credit Utilization",
            "Recent Late Payments",
            "Short Credit History"
          ]
          
        },
        "sources":[
          "Mpesa Statement",
          "Family Bank Statement",
          "Equity Bank Statement",
          "KCB Bank Statement"
        ]
    }
  return (
    <div className={`flex gap-10 mt-6 ${isProfileView ? 'justify-between' : ''}`}>
      <div className={`${isProfileView ? 'w-[60vw]' : 'w-full'}`}>
        <form className="rounded-md mb-5 bg-white dark:bg-primary-dark-light/5 p-5">
          <div className="flex flex-col justify-center sm:flex-row">
          <div className="flex-1  grid grid-cols-1 sm:grid-cols-2 gap-5">
            {headers.map((item, index) => (
              <div key={index}>
                <label className='text-primary text-[16px] lowercase' htmlFor={item}>{item}</label>
                <input
                  id={item}
                  value={formData[item]}
                  type="number"
                  className="form-input border-none p-3 lowercase placeholder:text-xs placeholder:text-gray-400 bg-black"
                  onChange={handleInputChange}
                  placeholder="Enter a number"
                />
              </div>
            ))}
            <div className="sm:col-span-2 mt-3">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!areAllFieldsFilled()}
                onClick={handleSave}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>
      <div>
      {isProfileView && (
        <ProfileDetails selectedUser={customer} handleCloseProfile={handleCloseProfile} />
      )}
      </div>
    </div>
  );
};

export default SingleUpload;
