import {useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import data from '../../../data/customerData.json'
import IconDownload from '../../../components/Icon/IconDownload';
import ProfileDetails from './ProfileDetails';
import { getCustomers } from '../../../services/apiService';
import * as XLSX from 'xlsx';
const UserManagement = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [revokedUsers, setRevokedUsers] = useState<Number[]>([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<Item | null>(null);
  const [isProfileView, setIsProfileView] = useState(false);
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const userData = await getCustomers();
        console.log(userData)
        setUsers(userData);
      } catch (err) {
        // setError(err.message);
      }
    };
    fetchCustomers();
    }, []);
  interface Item {
    id: number;
    name: string;
    email: string;
    phone: string;
    country: string;
    credit_score: number;
    cluster: string;
    contributingFactors: {
        positive: string[];
        negative: string[];
    };
    sources: string[];
  }
  const totalCustomers = users.length;
    let paginationNumbers = []
    const dataPerPage = 10
    const pageCount = Math.ceil(totalCustomers / dataPerPage);

    for (let i = 1; i <= pageCount; i++) {
        paginationNumbers.push(i);
    }

  if (pageCount > 5) {
    paginationNumbers.splice(3, pageCount - 5, '...' as any); 
    paginationNumbers.splice(pageCount - 2, 1);
  }

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = users.slice(indexOfFirstData, indexOfLastData);

    //search functionality
  const handleInputChange = (e:any) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm.trim()) {
      const filterItems = users.filter((value) =>
        value.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filterItems);
    } else {
      setFilteredItems([]);
    }
    setCurrentPage(1);
  };
    
    const handlePageChange = (selectedPage: any) => {
        setCurrentPage(selectedPage);
    };

  const displayedData = search ? filteredItems : currentData;
  const isRtl = useSelector((state: any) => state.themeConfig.rtlClass) === 'rtl';
  
  const handleViewProfile = (value: Item) => () => { 
    console.log(value)
    setSelectedUser(value);
    setIsProfileView(true);

  }
  
  const handleCloseProfile = () => {
    setIsProfileView(false);
  };
  const handleDownload = () => {
    // Create a new array of objects without the 'id' property
    const filteredUsers = users.map(({ id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    XLSX.writeFile(workbook, "customer_data.xlsx");
  };

  return (
    <div className={`flex gap-10 mt-6 ${isProfileView ? 'justify-between' : ''}`}>
      <div className={`panel ${isProfileView ? 'w-[60vw]' : 'w-full'} relative`}>
        <button className="flex gap-1 items-center justify-center absolute right-10 p-2 px-2 text-primary border border-primary rounded-md cursor-pointer group hover:text-white hover:bg-primary" onClick={handleDownload}>
          Download
          <IconDownload className="text-primary font-bold group-hover:text-white" />
        </button>
        <h5 className="font-semibold text-lg dark:text-white-light mb-5">Customer Data</h5>
        <div>
          <form className="mx-auto w-full mb-5 relative">
            <div>
              <input
                type="text"
                value={search}
                placeholder="Search customer by name..."
                className="form-input shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] bg-white placeholder:text-white-light rounded-full h-11 placeholder:tracking-wider ltr:pr-11 rtl:pl-11"
                onChange={handleInputChange}
              />
              <button type="button" className="btn btn-primary absolute ltr:right-1 rtl:left-1 inset-y-0 m-auto rounded-full w-10 h-10 p-0 flex items-center justify-center">
                <BsSearch className='text-[18px]' />
              </button>
            </div>
          </form>
          <div className="table-responsive mb-5 rounded-md">
            <table className={`table-hover `}>
              <thead>
                <tr>
                  <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>#</th>
                  {/* <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>User</th>
                  <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>Email</th> */}
                  <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>Sources</th>
                  {/* <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>Country</th> */}
                  <th className={`font-semibold text-white-light text-center ${isProfileView ? 'text-xs' : ''} `}>Credit Score</th>
                  <th className='font-bold text-white-light text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.map((value, index) => {
                  // Define allSources inside the map function
                  const allSources = [
                    ...(value.sources.mobileMoney || []),
                    ...(value.sources.bureau || []),
                    ...(value.sources.bankStatements || []),
                  ];

                  return (
                    <tr key={index} className={`cursor-pointer ${revokedUsers.includes(value.id) ? 'bg-dark' : ''}`}>
                      <td className='text-center text-info text-base'>
                        <div className="whitespace-nowrap">{value.id_number}</div>
                      </td>
                      {/* <td className={`text-center text-base ${isProfileView ? 'text-xs' : ''}`}>{value.name}</td>
        <td className={`text-center text-base text-info ${isProfileView ? 'text-xs' : ''}`}>{value.email}</td> */}
                      <td className={`text-center text-base sources-container ${isProfileView ? 'text-xs' : ''}`} title={allSources.length ? allSources.join(', ') : 'No sources available'}>
                        {allSources.length ? allSources.join(', ') : 'No sources available'}
                      </td>
                      <td className={`text-center text-base ${isProfileView ? 'text-xs' : ''}`}>{value.credit_score}</td>
                      <td className='flex justify-center'>
                        <button type="button" className="btn btn-outline-info text-xs" onClick={handleViewProfile(value as Item)}>view profile</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </table>
            <ul className="flex items-center justify-center space-x-1 rtl:space-x-reverse m-auto border border-gray-800 p-4">
              <li>
                <button
                  type="button"
                  className="flex justify-center font-semibold ml-2 px-2.5 py-2.5 rounded-full transition text-primary hover:text-white hover:bg-primary border border-primary active:bg-primary active:text-white cursor-pointer"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <RiArrowLeftDoubleFill />
                </button>
              </li>
              {paginationNumbers.map((pageNumber, index) => (
                <li key={index}>
                  <button
                    type="button"
                    className={`flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-${currentPage === pageNumber ? 'primary' : ''} hover:text-white hover:bg-primary dark:text-white dark:hover:bg-primary dark:bg-primary cursor-pointer`}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li>
                <button
                  type="button"
                  className="flex justify-center font-semibold ml-2 px-2.5 py-2.5 rounded-full transition text-primary hover:text-white hover:bg-primary border border-primary active:bg-primary active:text-white cursor-pointer"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <RiArrowRightDoubleFill />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isProfileView && (
        <ProfileDetails selectedUser={selectedUser} handleCloseProfile={handleCloseProfile} />
      )}
    </div>

    );
};

export default UserManagement;
