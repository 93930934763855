import { AiOutlineNumber } from 'react-icons/ai';
import { TbShieldStar } from 'react-icons/tb';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { FaChartArea } from 'react-icons/fa';
import { extractKeyStatistics } from '../utils';
import SummaryCard from './SummaryCard';
const CardsData = ({categoricalsummary, numbersummary}: {categoricalsummary: any, numbersummary: any}) => {
    const stats = extractKeyStatistics(categoricalsummary, numbersummary);
    const explanations = {
        count: "The total number of observations.",
        mean: "The average value of the observations.",
        std: "A measure of the amount of variation or dispersion of the observations.",
        min: "The minimum value of the observations.",
        "25%": "The 25th percentile value of the observations.",
        "50%": "(Median): The middle value of the observations.",
        "75%": "The 75th percentile value of the observations.",
        max: "The maximum value of the observations.",
        unique: "The number of unique values in the observations.",
        top: "The most frequent value in the observations.",
        freq: "The number of times the top value appears in the observations.",
    };
    return (
        <div className="mb-5 px-5 rounded-md py-1">
            {/* <p className='text-[17px] max-w-30 text-white font-semibold 2xl:my-0 my-3 '>Brief summary of data:</p> */}
            <div className=" grid grid-cols-4 gap-4">
                <SummaryCard title="No Of Rows" value={stats.count?.['ORIGINAL LOAN AMOUNT'] || 'N/A'} />
                <SummaryCard title="Unique Loan Statuses" value={stats.uniqueLoanStatus?.['LOAN STATUS'] || 'N/A'} />
                <SummaryCard title="Most Frequent Loan Status" value={stats.topLoanStatus?.['LOAN STATUS'] || 'N/A'} />
                <SummaryCard title="No Of Columns" value={'5'} />
            </div>
            <div className=" shadow-md rounded-lg m-4">
                <h2 className="text-xl font-semibold mb-4">Explanation of Index Values</h2>
                <div className="grid grid-cols-3">
                    {Object.entries(explanations).map(([key, explanation]) => (
                        <div className='flex gap-5 p-2 mt-3'>
                            <p className='text-info'>{key}</p>
                            <p className='text-gray-300 text-[14px]'>{explanation}</p>
                        </div>      
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardsData;
