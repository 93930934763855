import { Link } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { setPageTitle } from '../../store/themeConfigSlice';
import { useDispatch, useSelector } from 'react-redux';
import IconBell from '../../components/Icon/IconBell';
import { dataDescription } from '../../services/apiService';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import TableData from '../../components/DataDescription/components/tableData';
import CardsData from '../../components/DataDescription/components/cardsData';

const DataDescription = () => {
    const dispatch = useDispatch();

    //const filePondRef = useRef<FilePond>();
    const { user } = useSelector((state: any) => state.user);

    useEffect(() => {
        dispatch(setPageTitle('File Upload Preview'));
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null); 


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dataDescription(user.organisationID,user.id);
                setData(response);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once after the initial render

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const { categoricalsummary, numbersummary } = data;
    console.log(data)
    if (data.status === 'failed') {
        return <div className="bg-black rounded-md py-5 pl-2">
            <p className="lead mt-3 mb-4 dark:text-info text-[16px]">
                You need data uploaded to get a description
            </p>
        </div>
    }
    return (
        <div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="#" className="text-primary hover:underline">
                        Credit scoring
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Data Description</span>
                </li>
            </ul>
            <div className="pt-5 ">
                <div className="p-5 rounded-md flex items-center text-info overflow-x-auto whitespace-nowrap">
                    <div className=" rounded-full bg-info text-white p-1.5 ltr:mr-3 rtl:ml-3">
                        <IconBell />
                    </div>
                    <span className="ltr:mr-3 rtl:ml-3">Guide: </span>
                    <p className='text-[15px]'>This section displays a description of the data you submitted</p>
                </div>
            </div>
            <div>
                <CardsData categoricalsummary={categoricalsummary} numbersummary={numbersummary} />
                <TableData numbersummary={numbersummary}  />
            </div>
        </div>
    );
};
export default DataDescription;
