export const customers = [
    // Nairobi (50%)
    { location: 'Nairobi', latitude: -1.286389, longitude: 36.817223, name: 'Wanjiku Kamau' },
    { location: 'Nairobi', latitude: -1.28333, longitude: 36.81667, name: 'Omondi Mwangi' },
    { location: 'Nairobi', latitude: -1.28722, longitude: 36.82333, name: 'Akinyi Otieno' },
    { location: 'Nairobi', latitude: -1.289, longitude: 36.821, name: 'Kamau Nyambura' },
    { location: 'Nairobi', latitude: -1.284, longitude: 36.818, name: 'Achieng Karanja' },
    { location: 'Nairobi', latitude: -1.285, longitude: 36.819, name: 'Muthoni Gitonga' },
    { location: 'Nairobi', latitude: -1.287, longitude: 36.82, name: 'Njoki Karanja' },
    { location: 'Nairobi', latitude: -1.286, longitude: 36.822, name: 'Wanjiru Ngugi' },
    { location: 'Nairobi', latitude: -1.285, longitude: 36.824, name: 'Mwangi Kariuki' },
    { location: 'Nairobi', latitude: -1.284, longitude: 36.825, name: 'Njoroge Karanja' },
    { location: 'Nairobi', latitude: -1.288, longitude: 36.819, name: 'Chege Muthoni' },
    { location: 'Nairobi', latitude: -1.283, longitude: 36.821, name: 'Kagure Mwangi' },
    { location: 'Nairobi', latitude: -1.284, longitude: 36.823, name: 'Njeri Kamau' },
    { location: 'Nairobi', latitude: -1.287, longitude: 36.824, name: 'Jomo Kenyatta' },
    { location: 'Nairobi', latitude: -1.29, longitude: 36.826, name: 'Paul Tergat' },
    { location: 'Nairobi', latitude: -1.281, longitude: 36.82, name: 'Tegla Loroupe' },
    { location: 'Nairobi', latitude: -1.282, longitude: 36.818, name: 'Eliud Kipchoge' },
    { location: 'Nairobi', latitude: -1.283, longitude: 36.817, name: 'Brigid Kosgei' },
    { location: 'Nairobi', latitude: -1.284, longitude: 36.816, name: 'David Rudisha' },
    { location: 'Nairobi', latitude: -1.285, longitude: 36.815, name: 'Vivian Cheruiyot' },
    { location: 'Nairobi', latitude: -1.286, longitude: 36.814, name: 'Wilson Kipsang' },
    { location: 'Nairobi', latitude: -1.287, longitude: 36.813, name: 'Catherine Ndereba' },
    { location: 'Nairobi', latitude: -1.288, longitude: 36.812, name: 'Asbel Kiprop' },
    { location: 'Nairobi', latitude: -1.289, longitude: 36.811, name: 'Pamela Jelimo' },
    { location: 'Nairobi', latitude: -1.29, longitude: 36.81, name: 'Janet Jepkosgei' },
    { location: 'Nairobi', latitude: -1.291, longitude: 36.809, name: 'Moses Tanui' },
    { location: 'Nairobi', latitude: -1.292, longitude: 36.808, name: 'Samuel Wanjiru' },
    { location: 'Nairobi', latitude: -1.293, longitude: 36.807, name: 'Mary Keitany' },
    { location: 'Nairobi', latitude: -1.294, longitude: 36.806, name: 'Hellen Obiri' },
    { location: 'Nairobi', latitude: -1.295, longitude: 36.805, name: 'Joyciline Jepkosgei' },
    { location: 'Nairobi', latitude: -1.296, longitude: 36.804, name: 'Hyvin Kiyeng' },
    { location: 'Nairobi', latitude: -1.297, longitude: 36.803, name: 'Faith Kipyegon' },
    { location: 'Nairobi', latitude: -1.298, longitude: 36.802, name: 'Conseslus Kipruto' },
    { location: 'Nairobi', latitude: -1.299, longitude: 36.801, name: 'Timothy Cheruiyot' },
    { location: 'Nairobi', latitude: -1.3, longitude: 36.8, name: 'Eunice Sum' },
    { location: 'Nairobi', latitude: -1.301, longitude: 36.799, name: 'Julius Yego' },
    { location: 'Nairobi', latitude: -1.302, longitude: 36.798, name: 'Kipyegon Bett' },
    { location: 'Nairobi', latitude: -1.303, longitude: 36.797, name: 'Ferdinand Omanyala' },
    { location: 'Nairobi', latitude: -1.304, longitude: 36.796, name: 'Hyvin Kiyeng' },
    { location: 'Nairobi', latitude: -1.305, longitude: 36.795, name: 'Mark Otieno' },
    { location: 'Nairobi', latitude: -1.306, longitude: 36.794, name: 'Rhonex Kipruto' },
    { location: 'Nairobi', latitude: -1.307, longitude: 36.793, name: 'Lornah Kiplagat' },
    { location: 'Nairobi', latitude: -1.308, longitude: 36.792, name: 'Beatrice Chepkoech' },
    { location: 'Nairobi', latitude: -1.309, longitude: 36.791, name: 'Paul Koech' },
    { location: 'Nairobi', latitude: -1.31, longitude: 36.79, name: 'John Ngugi' },
    { location: 'Nairobi', latitude: -1.311, longitude: 36.789, name: 'Matthew Birir' },
    { location: 'Nairobi', latitude: -1.312, longitude: 36.788, name: 'Yobes Ondieki' },
    { location: 'Nairobi', latitude: -1.313, longitude: 36.787, name: 'Peter Rono' },
    { location: 'Nairobi', latitude: -1.314, longitude: 36.786, name: 'Henry Rono' },
    { location: 'Nairobi', latitude: -1.315, longitude: 36.785, name: 'Benjamin Limo' },
    { location: 'Nairobi', latitude: -1.316, longitude: 36.784, name: 'Edith Masai' },
    { location: 'Nairobi', latitude: -1.317, longitude: 36.783, name: 'John Korir' },
    { location: 'Nairobi', latitude: -1.318, longitude: 36.782, name: 'Patrick Ivuti' },
    { location: 'Nairobi', latitude: -1.319, longitude: 36.781, name: 'Emmanuel Korir' },

    // Nakuru (20%)
    { location: 'Nakuru', latitude: -0.3031, longitude: 36.08, name: 'Kiptoo Langat' },
    { location: 'Nakuru', latitude: -0.302, longitude: 36.07, name: 'Kiprono Kiptoo' },
    { location: 'Nakuru', latitude: -0.301, longitude: 36.079, name: 'Langat Kiptoo' },
    { location: 'Nakuru', latitude: -0.304, longitude: 36.09, name: 'Cheruiyot Langat' },
    { location: 'Nakuru', latitude: -0.305, longitude: 36.07, name: 'Kamau Rotich' },
    { location: 'Nakuru', latitude: -0.298, longitude: 36.085, name: 'Kiprotich Langat' },
    { location: 'Nakuru', latitude: -0.299, longitude: 36.083, name: 'Kipngeno Kimani' },
    { location: 'Nakuru', latitude: -0.297, longitude: 36.09, name: 'kiptanui Yego' },
    { location: 'Nakuru', latitude: -0.297, longitude: 36.09, name: 'Chebet Kimani' },
    { location: 'Nakuru', latitude: -0.3045, longitude: 36.077, name: 'Cherono Langat' },
    { location: 'Nakuru', latitude: -0.305, longitude: 36.078, name: 'Kimani Kiptoo' },
    { location: 'Nakuru', latitude: -0.306, longitude: 36.076, name: 'Kimani Cheruiyot' },
    { location: 'Nakuru', latitude: -0.307, longitude: 36.075, name: 'Kiptoo Cheruiyot' },
    { location: 'Nakuru', latitude: -0.308, longitude: 36.074, name: 'Kiprono Cheruiyot' },
    { location: 'Nakuru', latitude: -0.309, longitude: 36.073, name: 'Langat Kiprono' },
    { location: 'Nakuru', latitude: -0.31, longitude: 36.072, name: 'Langat Cheruiyot' },
    { location: 'Nakuru', latitude: -0.311, longitude: 36.071, name: 'Cheruiyot Kiprono' },
    { location: 'Nakuru', latitude: -0.312, longitude: 36.07, name: 'Rotich Kiprono' },
    { location: 'Nakuru', latitude: -0.313, longitude: 36.069, name: 'Rotich Langat' },
    { location: 'Nakuru', latitude: -0.314, longitude: 36.068, name: 'Langat Kiprotich' },
    { location: 'Nakuru', latitude: -0.315, longitude: 36.067, name: 'Kiprotich Kiprono' },
    { location: 'Nakuru', latitude: -0.316, longitude: 36.066, name: 'Kiprono Kiprotich' },
    { location: 'Nakuru', latitude: -0.317, longitude: 36.065, name: 'Cheruiyot Kiprotich' },
    { location: 'Nakuru', latitude: -0.318, longitude: 36.064, name: 'Cheruiyot Kiprono' },
    { location: 'Nakuru', latitude: -0.319, longitude: 36.063, name: 'Langat Kiprotich' },
    { location: 'Nakuru', latitude: -0.32, longitude: 36.062, name: 'Langat Kiprono' },
    { location: 'Nakuru', latitude: -0.321, longitude: 36.061, name: 'Kiprono Langat' },

    // Kiambu (clustered)
    { location: 'Kiambu', latitude: -1.1717, longitude: 36.8351, name: 'Wanjiru Kariuki' },
    { location: 'Kiambu', latitude: -1.166, longitude: 36.826, name: 'Kamau Njoroge' },
    { location: 'Kiambu', latitude: -1.173, longitude: 36.84, name: 'Nyambura Kamau' },
    { location: 'Kiambu', latitude: -1.169, longitude: 36.832, name: 'Kariuki Mwangi' },
    { location: 'Kiambu', latitude: -1.172, longitude: 36.83, name: 'Wairimu Kamau' },
    { location: 'Kiambu', latitude: -1.175, longitude: 36.831, name: 'Njuguna Kariuki' },
    { location: 'Kiambu', latitude: -1.17, longitude: 36.834, name: 'Muthoni Wanjiru' },
    { location: 'Kiambu', latitude: -1.168, longitude: 36.833, name: 'Njoroge Kamau' },
    { location: 'Kiambu', latitude: -1.174, longitude: 36.836, name: 'Kariuki Wairimu' },
    { location: 'Kiambu', latitude: -1.176, longitude: 36.837, name: 'Kamau Njuguna' },

    // Eldoret (clustered)
    { location: 'Eldoret', latitude: 0.5143, longitude: 35.2698, name: 'Korir Kiprop' },
    { location: 'Eldoret', latitude: 0.52, longitude: 35.27, name: 'Chepkorir Jepchirchir' },
    { location: 'Eldoret', latitude: 0.51, longitude: 35.28, name: 'Kipchumba Tanui' },
    { location: 'Eldoret', latitude: 0.515, longitude: 35.275, name: 'Tanui Kiprop' },
    { location: 'Eldoret', latitude: 0.519, longitude: 35.273, name: 'Jepchirchir Korir' },
    { location: 'Eldoret', latitude: 0.516, longitude: 35.271, name: 'Kiprop Tanui' },
    { location: 'Eldoret', latitude: 0.517, longitude: 35.27, name: 'Tanui Kipchumba' },
    { location: 'Eldoret', latitude: 0.518, longitude: 35.272, name: 'Jepchirchir Chepkorir' },
    { location: 'Eldoret', latitude: 0.519, longitude: 35.274, name: 'Korir Jepchirchir' },
    { location: 'Eldoret', latitude: 0.52, longitude: 35.276, name: 'Kiprop Chepkorir' },

    // Mombasa (sparse)
    { location: 'Mombasa', latitude: -4.0435, longitude: 39.6682, name: 'Amina Said' },
    { location: 'Mombasa', latitude: -4.045, longitude: 39.665, name: 'Hassan Amina' },
    { location: 'Mombasa', latitude: -4.046, longitude: 39.667, name: 'Fatuma Ali' },
    { location: 'Mombasa', latitude: -4.047, longitude: 39.669, name: 'Ali Fatuma' },
    { location: 'Mombasa', latitude: -4.048, longitude: 39.671, name: 'Said Hassan' },
    { location: 'Mombasa', latitude: -4.049, longitude: 39.673, name: 'Amina Ali' },
    { location: 'Mombasa', latitude: -4.05, longitude: 39.675, name: 'Hassan Said' },
    { location: 'Mombasa', latitude: -4.051, longitude: 39.677, name: 'Ali Hassan' },

    // Kisumu (sparse)
    { location: 'Kisumu', latitude: -0.0917, longitude: 34.7678, name: 'Odhiambo Otieno' },
    { location: 'Kisumu', latitude: -0.09, longitude: 34.77, name: 'Atieno Achieng' },
    { location: 'Kisumu', latitude: -0.089, longitude: 34.771, name: 'Otieno Odhiambo' },
    { location: 'Kisumu', latitude: -0.088, longitude: 34.772, name: 'Achieng Atieno' },
    { location: 'Kisumu', latitude: -0.087, longitude: 34.773, name: 'Omondi Odhiambo' },
    { location: 'Kisumu', latitude: -0.086, longitude: 34.774, name: 'Achieng Omondi' },

    // Nyeri (sparse)
    { location: 'Nyeri', latitude: -0.425, longitude: 36.951, name: 'Wambui Mugo' },
    { location: 'Nyeri', latitude: -0.43, longitude: 36.95, name: 'Kamau Karimi' },
    { location: 'Nyeri', latitude: -0.431, longitude: 36.949, name: 'Karimi Kamau' },
    { location: 'Nyeri', latitude: -0.432, longitude: 36.948, name: 'Mugo Wambui' },
    { location: 'Nyeri', latitude: -0.433, longitude: 36.947, name: 'Kamau Wambui' },
    { location: 'Nyeri', latitude: -0.434, longitude: 36.946, name: 'Wambui Kamau' },

    // Machakos (sparse)
    { location: 'Machakos', latitude: -1.5219, longitude: 37.2635, name: 'Mutua Kyalo' },
    { location: 'Machakos', latitude: -1.522, longitude: 37.26, name: 'Mueni Musyoka' },
    { location: 'Machakos', latitude: -1.523, longitude: 37.265, name: 'Musyoka Mutua' },
    { location: 'Machakos', latitude: -1.524, longitude: 37.261, name: 'Kyalo Mueni' },
    { location: 'Machakos', latitude: -1.525, longitude: 37.264, name: 'Mueni Kyalo' },

    // Isiolo (sparse)
    { location: 'Isiolo', latitude: 0.3546, longitude: 37.5821, name: 'Abdi Farah' },
    { location: 'Isiolo', latitude: 0.355, longitude: 37.58, name: 'Farah Abdi' },
    { location: 'Isiolo', latitude: 0.356, longitude: 37.579, name: 'Hassan Farah' },
    { location: 'Isiolo', latitude: 0.357, longitude: 37.578, name: 'Farah Hassan' },
    { location: 'Isiolo', latitude: 0.358, longitude: 37.577, name: 'Abdi Hassan' },

    // Embu (sparse)
    { location: 'Embu', latitude: -0.5373, longitude: 37.457, name: 'Njeri Wanjiru' },
    { location: 'Embu', latitude: -0.538, longitude: 37.456, name: 'Mwangi Njeri' },
    { location: 'Embu', latitude: -0.539, longitude: 37.455, name: 'Wanjiru Mwangi' },
    { location: 'Embu', latitude: -0.54, longitude: 37.454, name: 'Kamau Njeri' },
    { location: 'Embu', latitude: -0.541, longitude: 37.453, name: 'Njeri Kamau' },

    // Meru (sparse)
    { location: 'Meru', latitude: 0.047, longitude: 37.6496, name: 'Muriuki Muthomi' },
    { location: 'Meru', latitude: 0.048, longitude: 37.65, name: 'Mwenda Muriuki' },
    { location: 'Meru', latitude: 0.049, longitude: 37.651, name: 'Muthomi Mwenda' },
    { location: 'Meru', latitude: 0.05, longitude: 37.652, name: 'Kamau Muriuki' },
    { location: 'Meru', latitude: 0.051, longitude: 37.653, name: 'Muriuki Kamau' },
];
