import { useEffect, useState } from 'react';
import IconBookmark from '../Icon/IconBookmark'
import IconHome from '../Icon/IconHome';
import IconFolder from '../Icon/IconFolder';
import UploadEntity from './UploadEntity';
import UploadedEntities from './UploadedEntities';
const EntityScoring = () => {
  const options4 = [
    { value: 'orange', label: 'Orange' },
    { value: 'white', label: 'White' },
    { value: 'purple', label: 'Purple' },
  ];
  const [tabs, setTabs] = useState<string>('home');
  const toggleTabs = (name: string) => {
    setTabs(name);
  };

  return (
    <div className='w-full'>
      <div>
        <ul className="sm:flex font-semibold border-b border-[#ebedf2] dark:border-[#191e3a] mb-5 whitespace-nowrap overflow-y-auto">
          <li className="inline-block">
            <button
              onClick={() => toggleTabs('home')}
              className={`flex gap-2 p-4 border-b border-transparent hover:border-primary hover:text-primary ${tabs === 'home' ? '!border-primary text-primary' : ''}`}
            >
              <IconHome />
              Upload Entity
            </button>
          </li>
          <li className="inline-block">
            <button
              onClick={() => toggleTabs('uploaded-entities')}
              className={`flex gap-2 p-4 border-b border-transparent hover:border-primary hover:text-primary ${tabs === 'uploaded-entities' ? '!border-primary text-primary' : ''}`}
            >
              <IconFolder className="w-5 h-5" />
              Uploaded Entities
            </button>
          </li>

        </ul>
      </div>
      {tabs === 'home' ? (
        <UploadEntity />
      ) : (
        ''
      )}
      {tabs === 'uploaded-entities' ? (
        <UploadedEntities />
      ) : (
        ''
      )}
    </div>
  )
}

export default EntityScoring