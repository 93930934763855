import React, { useState, useRef, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../store';
import { setPageTitle } from '../store/themeConfigSlice';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
// @ts-ignore

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';
import { customers } from './map-dummy-data';
import MapLegend from '../components/Map/MapLegend/MapLegend';
import Search from '../components/Map/Search';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const MapPage: React.FC = () => {
    const dispatch = useDispatch();
    const [searchLocation, setSearchLocation] = useState<string>('');
    const [filteredMarkers, setFilteredMarkers] = useState(customers); // Initially show all markers
    const mapRef = useRef<L.Map>(null); // Ref for accessing the Leaflet map instance
    const [showSearch, setShowSearch] = useState(false);
    const toggleSearch = () => {
        setShowSearch((prev) => !prev);
    };

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
    });

    // Set page title on component mount
    useEffect(() => {
        dispatch(setPageTitle('Dashboard | Institutions'));
    }, [dispatch]);

    // Select theme and RTL state from Redux store
    const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl';

    // Function to handle search input change
    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchLocation(e.target.value);
    };

    // Function to handle search submission
    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        toggleSearch();
        // Filter markers based on search location
        const filtered = customers.filter((customer) => customer.location.toLowerCase().includes(searchLocation.toLowerCase()));

        // Update filtered markers state
        setFilteredMarkers(filtered);

        // Zoom to the first result if found
        if (filtered.length > 0) {
            const firstResult = filtered[0];
            const map = mapRef.current;
            if (map) {
                map.setView([firstResult.latitude, firstResult.longitude], 14);
            }
        }
    };

    // Define the center position of the map
    const centerPosition: [number, number] = [-0.0236, 37.9062];

    // Prepare heat map data
    const heatMapData = customers.map((customer) => [customer.latitude, customer.longitude, 1]);

    return (
        <div className="font-nunito">
            <MapContainer // @ts-ignore
                center={centerPosition}
                zoom={7}
                style={{ height: '85vh', width: '100%' }}
                ref={mapRef}
            >
                <TileLayer // @ts-ignore
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {filteredMarkers.map((customer, index) => (
                    <Marker key={index} icon={DefaultIcon} position={[customer.latitude, customer.longitude]}>
                        <Popup>
                            <div>
                                <strong>{customer.name}</strong>
                                <br />
                                {customer.location}
                            </div>
                        </Popup>
                    </Marker>
                ))}
                <HeatmapLayer
                    // @ts-ignore
                    points={heatMapData}
                    latitudeExtractor={(m) => m[0]}
                    longitudeExtractor={(m) => m[1]}
                    intensityExtractor={(m) => m[2]}
                    options={{
                        radius: 50, // Increase the radius to make points larger
                        blur: 20, // Increase the blur to blend points more
                        gradient: {
                            // Define a clear gradient for better visibility
                            0.2: 'blue',
                            0.4: 'lime',
                            0.6: 'yellow',
                            0.8: 'orange',
                            1.0: 'red',
                        },
                    }}
                />

                {/* search icon */}
                <Search searchLocation={searchLocation} setSearchLocation={setSearchLocation} handleSearchSubmit={handleSearchSubmit} showSearch={showSearch} toggleSearch={toggleSearch} />
            </MapContainer>
            <MapLegend />
        </div>
    );
};

interface HeatmapLayerProps {
    points: [number, number, number][];
    latitudeExtractor: (point: [number, number, number]) => number;
    longitudeExtractor: (point: [number, number, number]) => number;
    intensityExtractor: (point: [number, number, number]) => number;
    // @ts-ignore
    options: L.HeatLayerOptions;
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({ points, latitudeExtractor, longitudeExtractor, intensityExtractor, options }) => {
    const map = useMap();

    useEffect(() => {
        if (!map) return;
        // @ts-ignore
        const heatLayer = L.heatLayer(
            points.map((p) => [latitudeExtractor(p), longitudeExtractor(p), intensityExtractor(p)]),
            options
        ).addTo(map);

        return () => {
            map.removeLayer(heatLayer);
        };
    }, [map, points, latitudeExtractor, longitudeExtractor, intensityExtractor, options]);

    return null;
};

export default MapPage;
