import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
    id: string;
    name: string;
    email: string;
    isEmailVerified: boolean;
    organisationID: string;
    role: string;
}

interface UserState {
    user: User | null;
}

const initialState: UserState = {
    user: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser(state, action: PayloadAction<User>) {
            console.log('action: ', action);
            state.user = action.payload;
        },
        clearUser(state) {
            state.user = null;
        },
    },
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
