// authenticationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Token {
    token: string;
    expires: any;
}

interface AuthState {
    accessToken: Token | null;
    refreshToken: Token | null;
    isAuthenticated: boolean;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        loginSuccess(state, action: PayloadAction<{ access: Token; refresh: Token }>) {
            state.accessToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            state.isAuthenticated = true;
        },
        clearTokens(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
        },
    },
});

export const { loginSuccess, clearTokens } = authenticationSlice.actions;

// Action creator to load tokens from local storage with prefixed keys
export const loadTokensFromStorage = () => (dispatch: any) => {
    const accessToken = localStorage.getItem('cspre-accessToken');
    const accessTokenExpiry = localStorage.getItem('cspre-accessTokenExpiry');
    const refreshToken = localStorage.getItem('cspre-refreshToken');
    const refreshTokenExpiry = localStorage.getItem('cspre-refreshTokenExpiry');

    if (accessToken && refreshToken) {
        const tokens = { access: { token: accessToken, expires: accessTokenExpiry }, refresh: { token: refreshToken, expires: refreshTokenExpiry } };
        dispatch(loginSuccess(tokens));
    }
};

export default authenticationSlice.reducer;
