import { Button } from '@mui/material';
import React from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { demo_img } from '../../../assets/landingPage/constants';
import styles from './requestDemo.module.scss';

const RequestDemo = () => {
    return (
        <div className={styles.container} id='requestDemo'>
            <div className={styles.cta_container}>
                <h1>Request a demo</h1>
                <h2>Explore more of Caspre&apos;s Capabilities</h2>
                <p>Getting a demo is as easy as 1-2-3. Book your slot today</p>
                <Button className={styles.btn}>
                    Request Demo <ArrowCircleRightOutlinedIcon />
                </Button>
            </div>
            <div>
                <img src={demo_img} alt="demo image" />
            </div>
        </div>
    );
};

export default RequestDemo;
