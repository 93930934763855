import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ROCCurve = ({ aucrocData }: { aucrocData: any }) => {
  const { title, aucscore, auc_curve } = aucrocData;
  const chartRef = useRef(null);
  const [display, setDisplay] = React.useState(false);

  // Prepare data for the chart
  const chartData = {
    labels: auc_curve.map((point: { false_positive_rate: number }) =>
      point.false_positive_rate.toFixed(2)
    ),
    datasets: [
      {
        label: 'AUC Curve',
        data: auc_curve.map((point: { true_positive_rate: number }) => point.true_positive_rate),
        fill: false,
        borderColor: '#2196f3', // Change line color
        backgroundColor: 'rgba(75,192,192,0.2)',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'False Positive Rate',
          color: '#e5e7eb',
          font: {
            size: 14, // Change title font size
          },
        },
        grid: {
          color: '#1f2937', // Change grid line color
        },
        ticks: {
          color: '#2196f3', // Change tick color
        },
      },
      y: {
        title: {
          display: true,
          text: 'True Positive Rate',
          color: '#e5e7eb',
          font: {
            size: 14, // Change title font size
          },
        },
        grid: {
          color: '#1f2937', // Change grid line color
        },
        ticks: {
          color: '#2196f3', // Change tick color
        },
      },
    },
  };

  const handleDisplay = () => {
    setDisplay(!display);
  }

  return (
    <div>
      <div className='flex items-center justify-between'>
        <div className='p-5'>
          <h2 className='uppercase text-info'>{title}</h2>
          <p className='text-gray-200'>AUC Score: {aucscore.toFixed(4)}</p>
        </div>
        <p className='text-xs mr-5 cursor-pointer font-medium' onClick={() => handleDisplay()}>What is AUCROC?</p>
      </div>
      {
        display && (
          <div className='text-gray-200 p-5'>
            <p>AUCROC (Area Under the Receiver Operating Characteristic Curve) is a performance measurement for classification problems at various threshold settings. The ROC curve is a plot of the true positive rate (TPR) against the false positive rate (FPR) at different threshold levels.
            </p>
            <ul className=' list-decimal list-inside'>
              <li><strong className='text-info'>True Positive Rate (TPR): </strong>it is the ratio of correctly predicted positive observations to all actual positives. TPR = TP / (TP + FN)</li>
              <li><strong className='text-info'>False Positive Rate (FPR):  </strong>It is the ratio of incorrectly predicted positive observations to all actual negatives. FPR = FP / (FP + TN)</li>

            </ul>
            
          </div>
        )
      }
      <div style={{ height:'500px'  }}>
        <Line ref={chartRef} data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default ROCCurve;
