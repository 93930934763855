import ReactApexChart from 'react-apexcharts';
import { FC, useState, useEffect } from 'react';

interface AdvancedDataProps {
    significance: Array<significance>;
}

interface significance {
    y_attribute: string;
    x_attribute: string;
    significance: number;
}

const AdvancedAnalytics3: FC<AdvancedDataProps> = ({ significance }) => {
    const [chartArray, setChartArray] = useState([0, 0, 0, 0]);
    const [chartValueArray, setValueChartArray] = useState([1, 1, 1, 1]);

    useEffect(() => {
        const xArray: any = [];
        const xValueArray: any = [];
        if (significance && significance.length > 0) {
            const newSig = significance.map((entry) => ({
                y_attribute: entry.y_attribute,
                x_attribute: entry.x_attribute,
                significance: parseFloat(entry.significance.toFixed(2)),
            }));

            newSig.forEach((sig) => {
                xArray.push(sig['x_attribute']);
                xValueArray.push(sig.significance);
            });

            setChartArray(xArray);
            setValueChartArray(xValueArray);
        }
    }, [significance]);

    const pieChart: any = {
        series: chartValueArray,
        options: {
            chart: {
                height: 300,
                type: 'pie',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            labels: chartArray,
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                    },
                },
            ],
            stroke: {
                show: false,
            },
            legend: {
                position: 'right',
            },
        },
    };

    return (
        <div className="w-full">
            <div className="flex w-full justify-between space-x-2">
                <div className="w-full">
                    <ReactApexChart series={pieChart.series} options={pieChart.options} className="rounded-lg bg-white dark:bg-black overflow-hidden" type="pie" height={300} />
                </div>
            </div>
        </div>
    );
};

export default AdvancedAnalytics3;
