export const contributingFactors: { positiveFactors: string[]; negativeFactors: string[] } = {
  positiveFactors: [
    "Timely payment history",
    "Low credit utilization ratio",
    "Long credit history",
    "Diverse credit mix",
    "Few or no hard inquiries",
    "Low debt-to-income ratio",
    "High-income level",
    "Stable employment history",
    "No history of bankruptcy",
    "Minimal late payments",
    "No accounts in collections",
    "Positive mortgage payment history",
    "Positive auto loan payment history",
    "Low credit card balances",
    "Regular savings contributions",
    "Increasing net worth",
    "No recent late payments",
    "Responsible use of credit",
    "Increasing credit limits",
    "Consistent credit behavior",
    "Paying more than the minimum payment",
    "No charge-offs",
    "Low number of new credit accounts",
    "Established relationship with financial institutions",
    "Timely utility bill payments",
    "Positive rent payment history",
    "Steady increase in savings",
    "Avoidance of payday loans",
    "Positive loan refinancing history",
    "Low loan-to-value ratio on secured loans"
  ],
  negativeFactors: [
    "Late payments",
    "High credit utilization ratio",
    "Short credit history",
    "Limited credit mix",
    "Multiple hard inquiries",
    "High debt-to-income ratio",
    "Low income level",
    "Unstable employment history",
    "History of bankruptcy",
    "Frequent late payments",
    "Accounts in collections",
    "Negative mortgage payment history",
    "Negative auto loan payment history",
    "High credit card balances",
    "Irregular savings contributions",
    "Decreasing net worth",
    "Recent late payments",
    "Irresponsible use of credit",
    "Decreasing credit limits",
    "Inconsistent credit behavior",
    "Making only minimum payments",
    "Charge-offs",
    "High number of new credit accounts",
    "Poor relationship with financial institutions",
    "Late utility bill payments",
    "Negative rent payment history",
    "Decline in savings",
    "Use of payday loans",
    "Negative loan refinancing history",
    "High loan-to-value ratio on secured loans"
  ]
};
