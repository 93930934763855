import React from 'react'
import IconChecks from '../Icon/IconChecks'

const EntityReports = () => {
  return (
    <div className='p-5 grid grid-cols-3 gap-10'>
      {/* <p className='text-lg text-primary text-center'>Reports from the analysis of the entity uploaded</p> */}
      {/* <p className=' tracking-wide w-[99%] leading-6 font-thin mt-2 text-base text-white-light'>The XYZ project, a renewable energy initiative based in California, aims to develop a state-of-the-art solar power plant. With a strategic location and strong management team, the project is well-positioned to capitalize on the growing demand for clean energy. The project is expected to generate significant returns over the next five years, driven by innovative technology and favorable market conditions. However, potential regulatory changes pose a notable risk that must be carefully monitored.</p> */}
      
      <div>
        {/* <p className=' tracking-wide w-[99%] leading-6 font-thin mt-2 text-base text-white-light'>The XYZ project, a renewable energy initiative based in California, aims to develop a state-of-the-art solar power plant. With a strategic location and strong management team, the project is well-positioned to capitalize on the growing demand for clean energy. The project is expected to generate significant returns over the next five years, driven by innovative technology and favorable market conditions. However, potential regulatory changes pose a notable risk that must be carefully monitored.</p> */}
        <div className='flex flex-col gap-2'>
          <p className='text-lg text-white'>Brief Summary of Data</p>
        <div className='flex border border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Name</p>
          <p className='font-medium'>XYZ project</p>
        </div>
        <div className='flex border border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Entity Type</p>
          <p className='font-medium'>Project</p>
        </div>
        <div className='flex border border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Sector</p>
          <p className='font-medium'>Renewable Energy</p>
        </div>
        <div className='flex border border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Location</p>
          <p className='font-medium'>California</p>
        </div>
        <div className='flex border border-black p-5 rounded-md text-primary justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light '>Scale of entity:</p>
          <p className='font-medium'>Small corporation</p>
        </div>
        </div>

        <div className='flex flex-col gap-2 mt-5 py-2'>
        <p className='text-lg text-white '>Projected ROI</p>
        <div className='flex border-b border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Best case:</p>
          <p className='text-success'>20%</p>
        </div>
        <div className='flex border-b border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Most Likely:</p>
          <p className='text-warning'>15%</p>
        </div>
        <div className='flex border-b border-black p-5 rounded-md text-info justify-between gap-4 py-3 mt-2'>
          <p className='text-sm text-white-light'>Worst case:</p>
          <p className='text-danger'>10%</p>
        </div>
      </div>
      </div>
      <div className=' p-5 rounded-md'>
        <div className='flex flex-col gap-2 mt-5'>
          <p className='text-white text-lg'>Value Drivers</p>
          <ul className=' list-disc flex flex-col gap-3 py-2 text-white-dark font-medium tracking-wide'>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Market Demand
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Innovative Technology
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Strategic Partnerships
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Scalability Potential
            </li>
            
          </ul>
          
        </div>
        <div className='flex flex-col gap-2 mt-5'>
          <p className='text-white text-lg'>Recommendations</p>
          <ul className=' flex flex-col gap-3 py-2  text-white-dark font-medium tracking-wide'>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Monitor Regulatory Changes which will affect future operations
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Analyze Financial Health of the project
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Understand Market Needs to assess the demand for the project
            </li>
            <li className='flex items-center gap-1'>
              <IconChecks className='text-info'/>
              Evaluate Competition to identify potential threats
            </li>
            
          </ul>
          
        </div>
<div className=''>
  <p className='text-success mt-10 text-base'>
    Based on the analysis, the XYZ Project is a profitable project to invest in
  </p>
</div>
      </div>

      <div className=' p-5 rounded-md'>
        <div className='flex flex-col gap-2 mt-5 '>
          <p className='text-primary'>Risk Assessment</p>
          <p className='text-warning font-medium py-2'>Market Risk</p>
          
        <ul className=' list-disc px-5 flex flex-col gap-3 text-white-dark font-medium tracking-wide'>
          <li>
            Market Competition: Increased competition can erode market share and reduce profit margins.
          </li>
          <li>
            Market Demand: Fluctuations in demand can impact revenue and profitability.
          </li>
          <li>
            Market Regulation: Changes in regulations can increase costs and limit growth opportunities.
          </li>
          <li>
            Price Volatility: Significant changes in the prices of raw materials or products can affect profitability.
          </li>
        </ul>
      </div>
      <div className='flex flex-col gap-2 mt-5'>
        <ul className=' list-disc px-5 flex flex-col gap-3'>
          <p className='text-white-light font-medium py-2'>Financial Risk</p>
          <li>
            Market Competition: Increased competition can erode market share and reduce profit margins.
          </li>
          <li>
            Market Demand: Fluctuations in demand can impact revenue and profitability.
          </li>
          <li>
            Market Regulation: Changes in regulations can increase costs and limit growth opportunities.
          </li>
          <li>
            Price Volatility: Significant changes in the prices of raw materials or products can affect profitability.
          </li>
        </ul>
      </div>
      <div className='flex flex-col gap-2 mt-5 '> 
        <ul className=' list-disc px-5 flex flex-col gap-3'>
          <p className='text-white-light font-medium py-2'>Operational Risk</p>
          <li>
            Market Competition: Increased competition can erode market share and reduce profit margins.
          </li>
          <li>
            Market Demand: Fluctuations in demand can impact revenue and profitability.
          </li>
          <li>
            Market Regulation: Changes in regulations can increase costs and limit growth opportunities.
          </li>
          <li>
            Price Volatility: Significant changes in the prices of raw materials or products can affect profitability.
          </li>
        </ul>
      </div>
      </div>

    </div>
  )
}

export default EntityReports