// utils/dataUtils.js
export const extractKeyStatistics = (categoricalSummary, numberSummary) => {
  const stats = {
    count: numberSummary.find(item => item.index === 'count'),
    mean: numberSummary.find(item => item.index === 'mean'),
    std: numberSummary.find(item => item.index === 'std'),
    min: numberSummary.find(item => item.index === 'min'),
    max: numberSummary.find(item => item.index === 'max'),
    uniqueLoanStatus: categoricalSummary.find(item => item.index === 'unique'),
    topLoanStatus: categoricalSummary.find(item => item.index === 'top'),
  };
  return stats;
};

export const prepareChartData = (numberSummary, key) => {
  const labels = numberSummary.map(item => item.index);
  const data = numberSummary.map(item => item[key]);
  return {
    labels,
    datasets: [{
      label: key,
      data,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1,
    }],
  };
};
