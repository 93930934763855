import React from 'react';
import styles from './trackrecord.module.scss';
import { Grid } from '@mui/material';

const record = [
    { number: 45, desc: `Financial institutions trust Caspre’s credit scoring abilities` },
    { number: `20,000+`, desc: `Client’s credit scores have been accurately determined by Caspre` },
    { number: `3+`, desc: `Years Caspre has been revolutionising the Financial space` },
];

const TrackRecord = () => {
    return (
        <div className={styles.parent} id='track_record'>
            <Grid container spacing={6} className={styles.container}>
                {record.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <div key={index} className={styles.record_container}>
                            <h1 className={styles.number}>{item.number}</h1>
                            <p className={styles.desc}>{item.desc}</p>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default TrackRecord;
