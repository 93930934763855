import React, { useState } from 'react';
import styles from './testimonialsStyles.module.scss';
import { closing_quote, opening_quote } from '../../../assets/landingPage/constants';
import { Button, Tab, Tabs } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { testimonies } from './testimonies';

const testimony = {
    width: 500,
    p: 2,
    m: 3,
    pt: 6,
    textTransform: 'inherit',
    // backgroundColor: '#c5ffe2',
    borderRadius: 5,
};

const Testimonials = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleTabClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const nextTab = () => {
        setValue((prevValue) => (prevValue === testimonies.length - 1 ? 0 : prevValue + 1));
    };

    const prevTab = () => {
        setValue((prevValue) => (prevValue === 0 ? testimonies.length - 1 : prevValue - 1));
    };
    return (
        <div className={styles.container} id='testimonials'>
            <div className={styles.heading_container}>
                <h2>testimonials</h2>
                <h1>Don&apos;t Take Our Word for it. Here is What Caspre&apos;s Trusted Customers have to Say</h1>
            </div>

            <div className={styles.testimonials_contianer}>
                <Tabs
                    className={styles.tabContainer}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    {testimonies.map((item, index) => (
                        <Tab
                            key={index}
                            sx={{
                                ...testimony,
                                transform: value === index ? 'scale(0.95)' : 'scale(1)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                            label={
                                <div onClick={handleTabClick} className={styles.testimonial}>
                                    <img className={styles.img} src={item.img} />
                                    <div className={styles.testimony_container}>
                                        <img src={opening_quote} />
                                        <p>{item.testimony}</p>
                                        <img className={styles.closing_quote} src={closing_quote} />
                                        <h1>{item.name}</h1>
                                        <h3>{item.designation}</h3>
                                    </div>
                                </div>
                            }
                        />
                    ))}
                </Tabs>
            </div>

            <div className={styles.control_btn_container}>
                <Button onClick={prevTab} className={styles.btn}>
                    <ChevronLeftIcon className={styles.icon} />
                </Button>
                <Button onClick={nextTab} className={styles.btn}>
                    <ChevronRightIcon className={styles.icon} />
                </Button>
            </div>
        </div>
    );
};

export default Testimonials;
