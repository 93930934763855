import { useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../store/themeConfigSlice';
import DataTable from '../../components/Tables/DataTable';

interface UserManagementComponent {
    data?: any;
}
const UserManagement: FC<UserManagementComponent> = ({ data }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('User Management'));
    });
    return (
        <div>
            <DataTable userdata={data} />
        </div>
    );
};

export default UserManagement;
