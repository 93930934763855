import React from 'react';
import styles from './TheLandingPage.module.scss';
import HeroSection from './HeroSection/HeroSection';
import Patners from './Patners/Patners';
import ValueProposition from './ValueProposition/ValueProposition';
import Features from './features/Features';
import TrackRecord from './TrackRecord/TrackRecord';
import Testimonials from './Testimonials/Testimonials';
import Faqs from './Faqs/Faqs';
import RequestDemo from './RequestDemo/RequestDemo';
import Footer from './Footer/Footer';

const TheLandingPage = () => {
    return (
        <div>
            <div className={styles.jumbotron}>
                <p>
                This is site is only accesible on tablet, desktop devices
                </p>
            </div>
            <div className={styles.container}>
                <HeroSection />
                <div className={styles.pad_components}>
                    <Patners />
                    <ValueProposition />
                    <Features />
                    <TrackRecord />
                </div>
                <Testimonials />
                <div className={styles.pad_components}>
                    <Faqs />
                    <RequestDemo />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default TheLandingPage;
