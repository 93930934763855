import { FC, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface AdvancedDataProps {
    significance: Array<significance>;
}

interface significance {
    y_attribute: string;
    x_attribute: string;
    significance: number;
}

const AdvancedAnalytics2: FC<AdvancedDataProps> = ({ significance }) => {
    const [chartArray, setChartArray] = useState([0, 0, 0, 0]);
    const [chartValueArray, setValueChartArray] = useState([1, 1, 1, 1]);
    const [newSignificance, setNewSignificance] = useState([]);

    const columnChart: any = {
        series: [
            {
                name: 'Y Reference',
                data: [1, 1, 1, 1],
            },
            {
                name: 'Significance',
                data: chartValueArray,
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: true ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: chartArray,
                axisBorder: {
                    color: true ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: false ? true : false,
                labels: {
                    offsetX: false ? -10 : 0,
                },
            },
            tooltip: {
                theme: true ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    useEffect(() => {
        const xArray: any = [];
        const xValueArray: any = [];
        if (significance && significance.length > 0) {
            const newSig = significance.map((entry) => ({
                y_attribute: entry.y_attribute,
                x_attribute: entry.x_attribute,
                significance: parseFloat(entry.significance.toFixed(2)),
            }));

            newSig.forEach((sig) => {
                xArray.push(sig['x_attribute']);
                xValueArray.push(sig.significance);
            });

            setChartArray(xArray);
            setValueChartArray(xValueArray);
        }
    }, [significance]);
    return (
        <div className="w-full">
            <div className="flex w-full justify-between space-x-2">
                <div className="w-full">
                    <ReactApexChart series={columnChart.series} options={columnChart.options} className="rounded-lg bg-white dark:bg-black overflow-hidden" type="bar" height={300} />
                </div>
            </div>
        </div>
    );
};

export default AdvancedAnalytics2;
