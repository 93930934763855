// FilePondComponent.tsx

import React, { useRef } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'; // Import FilePond styles
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { uploadHistoricalFile } from '../../services/apiService';
import { User } from '../../types';
import { useDispatch } from 'react-redux';
import { setFileUpload } from '../../store/fileUploadSlice';
registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);

interface FilePondComponentProps {
    onFileUpload: (serverID: string) => void;
    user: User;
}


const PredictUploadComponent: React.FC<FilePondComponentProps> = ({ onFileUpload, user, setIsFileUpload }) => {
    let BASE_URL = import.meta.env.PROD ? import.meta.env.VITE_API_URL_PROD : import.meta.env.VITE_API_URL;

    const dispatch = useDispatch();

    //console.log('user: ', user);
    const pondRef = useRef<FilePond>();


    // Function to handle file upload
    const handleInit = () => {
        console.log('FilePond instance has initialized');
    };

    // Function to handle file upload progress
    const handleProgress = (file: any, progress: any) => {
        console.log('File upload progress:', progress);
    };

    // Function to handle file upload success
    const handleFileSuccess = (file: any) => {
        const serverID = file.serverId;
        console.log('File upload success:', file);
        console.log('get server id: ', serverID);
        onFileUpload(serverID);
        setIsFileUpload(true);
    };

    // Function to handle file upload error
    const handleFileError = (file: any, error: any) => {
        console.error('File upload error:', error);
    };

    // Function to handle file upload
    const handleFileProcess = async (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any, abort: any) => {
        console.log('file: ', file);

        try {
            const historicalData = {
                file: file,
            };
            await uploadHistoricalFile(historicalData);
            load(); // Signal that the chunk has been uploaded successfully
        } catch (err) {
            error('Error uploading chunk');
        }
    };

    const onAddFile = async (error: any, file: any) => {
        console.log('add file: ', file);
    };

    return (
        <div>
            <h5 className=' mb-10 text-primary text-base'>Upload CSV/Excel file with customer data</h5>
            <FilePond
                chunkUploads={true}
                chunkForce={true}
                onaddfile={(error, file) => onAddFile(error, file)}
                maxFiles={1}
                chunkSize={2000000}
                server={{
                    url:`${BASE_URL}/upload/predict`,
                    headers: {
                        user: 'test user id',
                    },
                }}
                oninit={() => handleInit()}
                onprocessfileprogress={handleProgress}
                onprocessfile={(error, file) => (error ? handleFileError(file, error) : handleFileSuccess(file))}
                acceptedFileTypes={['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            />
        </div>

    );
};

export default PredictUploadComponent;
