import React from 'react';
import styles from './ValueProposition.module.scss';
import { Card, Grid } from '@mui/material';
import { credit_insights, enhanced_profitability, scoring_accuracy } from '../../../assets/landingPage/constants';

const whatWeOffer = [
    {
        title: 'Credit Scoring Accuracy',
        desc: 'Utilize advanced AI and behavioral analytics to achieve unparalleled accuracy in credit scoring, enhancing your risk management capabilities.',
        img: scoring_accuracy,
    },
    {
        title: 'Comprehensive Credit Insights',
        desc: 'Gain deep, actionable insights from detailed credit profiles, empowering your institution to make informed lending decisions and improve client offerings.',
        img: credit_insights,
    },
    {
        title: 'Enhanced Profitability',
        desc: 'Improve your bottom line with precise credit assessments, leading to better loan performance and increased profitability.',
        img: enhanced_profitability,
    },
];

const ValueProposition = () => {
    return (
        <div className={styles.container} id='value_proposition'>
            <div className={styles.heading_container}>
                <h1>Our Value Proposition</h1>
                <p>We are different, here's why.</p>
            </div>

            <Grid container spacing={3} className={styles.card_container}>
                {whatWeOffer.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <Card className={styles.card_item}>
                            <img src={item.img} alt={item.title} />
                            <h1>{item.title}</h1>
                            <p>{item.desc}</p>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ValueProposition;
