import axios, { AxiosResponse } from 'axios';

let BASE_URL = import.meta.env.PROD ? import.meta.env.VITE_API_URL_PROD : import.meta.env.VITE_API_URL;

interface LoginData {
    email: string;
    password: string;
}

interface RegisterData {
    name: string;
    email: string;
    password: string;
    organisationID: string;
}

interface Organisation {
    id: string;
    name: string;
    description: string;
    address: string;
    contactPerson: string;
    email: string;
    phone: string;
    website: string;
}

interface historicalData {
    file: File;
}

interface RefreshToken {
    refreshToken: string;
}

interface ServerIDObject {
    serverID: string;
}

interface UploadStatusObject {
    userId: string;
    status: boolean;
}

interface getBinariesObject {
    filename: string | undefined;
    column: string;
    filetype: string | undefined;
}

interface UploadFilesToServerObject {
    filename: String | undefined;
    targetname: String;
    rejectname: String;
    approvename: String;
    userID: String;
    fileID: String | undefined;
}

interface InitiateTrainingObject {
    tableName: String | undefined;
    companyID: String | undefined;
    fileID: String | undefined;
}

export const login = async (loginData: LoginData): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/auth/login`, loginData);
        return response.data;
        console.log('response: ', response);
    } catch (error: any) {
        console.log(error);
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error(error.message);
        }
    }
};

export const register = async (registerData: RegisterData): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/auth/register`, registerData);
        return response.data;
    } catch (error: any) {
        console.log('error: ', error);
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error(error);
        }
    }
};

export const refreshTokens = async (refreshToken: RefreshToken): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/auth/refresh-tokens`, refreshToken);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const uploadHistoricalFile = async (formData: historicalData): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/historical-data`, formData);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const findUpload = async (serverIDObject: ServerIDObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/find`, serverIDObject);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getBinaries = async (binariesReqObj: getBinariesObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/extract-column-values`, binariesReqObj);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const uploadFileToServer = async (uploadFileToServerObject: UploadFilesToServerObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/server-upload`, uploadFileToServerObject);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const advancedAnalytics = async (advancedAnalyticsObject: InitiateTrainingObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/advanced-analytics`, advancedAnalyticsObject);
        //console.log('response: ', response);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const InitiateTraining = async (initiateTrainingObject: InitiateTrainingObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/initiate-training`, initiateTrainingObject);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const checkTraining = async (checkTrainingObject: InitiateTrainingObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/upload/check-training`, checkTrainingObject);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getOrganisations = async (): Promise<Organisation[]> => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/organisation`);
        return response.data.results as Organisation[];
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getUserUploadStatus = async (userId: string): Promise<any> => {
    console.log('userId: ', userId);
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/users/upload/status/${userId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const setUserUploadStatus = async (uploadStatusObject: UploadStatusObject): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.patch(`${BASE_URL}/users/update/uploadstatus/${uploadStatusObject.userId}`, {
            status: uploadStatusObject.status,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getDataDescription = async () => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const body = {
        companyID: '660d1a50dab5358ab47ec50d',
        tablename: 'trainSampledata',
    };
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/describe/`, body, { headers });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getUsers = async () => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/users/getusers`);
        return response.data.results;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getCustomers = async () => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/customers/`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getCustomerById = async (customerId: string) => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/customers/${customerId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const updateUserAccess = async (userId: string, value: boolean) => {
    try {
        const response: AxiosResponse = await axios.patch(`${BASE_URL}/users/getusers/${userId}`, {
            disabled: value,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const uploadBusinessPlan = async (data: any): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/api/upload/`, data);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const loadUploadedEntitities = async (userId: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/entity/${userId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const loadEntityReport = async (fileId: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/entityreport/${fileId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const dataDescription = async (companyID: string, userID: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/data-description`, {
            companyID: companyID,
            userID: userID,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const modelMetrics = async (companyID: string, userID: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/model-metrics`, {
            companyID: companyID,
            userID: userID,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const predictions = async (): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/predictfiles`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getCreditProfilesForCustomers = async (): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/credit-profile`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getCreditProfilesForCustomer = async (userID: string, customerID: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/credit-profile/detailed`, {
            customerID: customerID,
            userID: userID,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
