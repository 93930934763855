import { client_img, collins_img, gibson_img, kamara_img, wafula_img } from '../../../assets/landingPage/constants';

export const testimonies = [
    {
        img: kamara_img,
        testimony: `Caspre has set a new benchmark in the credit scoring industry. Its advanced analytics and comprehensive credit profiles have empowered our financial institution to make more informed decisions. The integration of behavioral and geolocation data into credit assessments is a game-changer, offering us deeper insights into customer behavior and risk. Caspre is undoubtedly driving significant value and transforming the credit scoring landscape.`,
        name: `John Kamara`,
        designation: `CEO, Adanian Labs`,
    },
    {
        img: client_img,
        testimony: `The technological prowess of Caspre is truly remarkable. From enhanced upload capabilities to sophisticated data visualization and dynamic user clustering techniques, Caspre stands out as a cutting-edge solution. The platform's ability to incorporate external factors and pseudo data into credit scoring models provides us with a nuanced and comprehensive risk assessment that is unparalleled. Caspre embodies our vision of leveraging technology to drive financial innovation and inclusion.`,
        name: `Bendon K. Murgor`,
        designation: `CTO, Adanian Labs`,
    },
    {
        img: gibson_img,
        testimony: `Caspre’s innovative approach to credit scoring is both practical and user-friendly. The focus on user experience, with improved upload processes and intuitive dashboards, makes navigating and utilizing the system seamless. The Behavioral Data Bank not only enhances our credit scoring process but also opens new revenue streams through data monetization. Caspre’s holistic approach is making a significant impact on our operations and the broader market.`,
        name: `Gibson Gisore`,
        designation: `COO, Adanian Labs`,
    },
    {
        img: wafula_img,
        testimony: `Caspre goes beyond traditional credit scoring by offering invaluable insights through its entity scoring module. This feature is essential for evaluating investment opportunities, providing detailed risk assessments and visual reporting that give us a clear understanding of potential investments. The integration of behavioral data ensures more accurate and comprehensive credit profiles, making Caspre a revolutionary tool in our industry.`,
        name: `John Wafula`,
        designation: `Commercial lead, AICE`,
    },
    {
        img: collins_img,
        testimony: `At Caspre, our goal has always been to empower financial institutions with deeper insights and more precise credit assessments. The advanced features in Version 2, including Behavioral Analytics and Pseudo Data Integration, offer a holistic view of an individual’s creditworthiness. Caspre is redefining credit scoring and risk management, equipping both financial institutions and individuals with the tools they need to achieve success.`,
        name: `Collins Nyamao`,
        designation: `Team lead, Caspre`,
    },
];
