import React, { useEffect, useState } from 'react';

interface UploadedFile {
    serverID: string;
    filename: string;
    headers: any;
    fileType: string;
    uploadedAt: string;
    id: string;
    fileTrained: String;
    originalName: String;
    fileUploadedToServer: String;
    fileTableReference: String;
}

interface FileTableComponentProps {
    file: UploadedFile | null;
}
const FileTable: React.FC<FileTableComponentProps> = ({ file }) => {
    const date = file?.uploadedAt ? formatReadableDate(file.uploadedAt) : '';
    console.log('date: ', date);

    const tableData = [
        {
            id: 1,
            label: 'File name',
            value: file?.originalName,
        },
        {
            id: 2,
            label: 'Column count',
            value: file?.headers ? file.headers.length : 0,
        },
        {
            id: 3,
            label: 'File size',
            value: '6000 bytes',
        },
        {
            id: 4,
            label: 'File type',
            value: file?.fileType,
        },
        {
            id: 5,
            label: 'Uploaded at',
            value: file?.uploadedAt ? date : 'undefined',
        },
    ];

    return (
        <div className="table-responsive mb-5">
            <table>
                <thead>
                    <tr>
                        {/* <th>#</th> */}
                        <th>Key</th>
                        <th className="">Description</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((data) => {
                        return (
                            <tr key={data.id}>
                                {/* <td>{data.id}</td> */}
                                <td>
                                    <div className="whitespace-nowrap">{data.label}</div>
                                </td>
                                <td className="">{data.value}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default FileTable;

const formatReadableDate = (dateString: string) => {
    // Create a new Date object from the input date string
    const date = new Date(dateString);

    // Extract date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based (0 = January)
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad single-digit day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format the date and time string in the desired format
    const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${hours}:${minutes}`;

    return formattedDate;
};
