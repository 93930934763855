import { Link } from 'react-router-dom';
import React, { useEffect,useState } from 'react';
import { setPageTitle } from '../../store/themeConfigSlice';
import { useDispatch, useSelector } from 'react-redux';
import IconBell from '../../components/Icon/IconBell';
import { modelMetrics } from '../../services/apiService';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Table from '../../components/MLAnalytics/components/table';
import IconPlusCircle from '../../components/Icon/IconPlusCircle';
import IconMinusCircle from '../../components/Icon/IconMinusCircle';
import AnimateHeight from 'react-animate-height';

const MlAnalytics = () => {
    const dispatch = useDispatch();

    //const filePondRef = useRef<FilePond>();
    const { user } = useSelector((state: any) => state.user);

    useEffect(() => {
        dispatch(setPageTitle('File Upload Preview'));
    });
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null); 
    const [display, setDisplay] = React.useState(false);
    const [active1, setActive1] = useState<number | null>(null);
    const [active2, setActive2] = useState<any>(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await modelMetrics(user.organisationID, user.id);
                setData(response);
                console.log(response)
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); 

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const handleDisplay = () => {
        setDisplay(!display);
    }
    const metricsData = [
        {
            id: 1,
            title: "Labels?",
            description: "This refers to the class labels for which the metrics are being calculated. In your example, you have two labels, 0 and 1, and an average (which might be a weighted or macro average).",
        },
        {
            id: 2,
            title: "Precision?",
            description: "This is the ratio of true positive predictions to the total predicted positives. It indicates how many of the predicted positive cases were actually positive.",
            code: "Precision = True Positives / (True Positives + False Positives)"
        },
        {
            id: 3,
            title: "Recall?",
            description: "This is the ratio of true positive predictions to the total actual positives. It shows how many of the actual positive cases were correctly predicted.",
            code: "Recall = True Positives / (True Positives + False Negatives)"
        },
        {
            id: 5,
            title: "F1-score?",
            description: "This is the harmonic mean of precision and recall. It provides a single metric that balances both precision and recall.",
            code: "F1-score = 2 * (Precision * Recall) / (Precision + Recall)"
        }
    ];
    const toggleActive = (id: number) => {
        setActive1(active1 === id ? null : id);
    };
    return (
        <div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="#" className="text-primary hover:underline">
                        Credit scoring
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>ML Analytics</span>
                </li>
            </ul>
            <div className="pt-5 space-y-8">
                <div className="bg-primary/5 p-5 rounded-md flex items-center text-info overflow-x-auto whitespace-nowrap">
                    <div className="ring-2 ring-primary/30 rounded-full bg-primary text-white p-1.5 ltr:mr-3 rtl:ml-3">
                        <IconBell />
                    </div>
                    <span className="ltr:mr-3 rtl:ml-3">Guide: </span>
                    <p>This section displays the Model's Analytics</p>
                </div>
            </div>
            <div>
                <p className='mt-5 hover:border-b hover:w-[230px] hover:border-info cursor-pointer text-info' onClick={handleDisplay}>What is Recall, F1Score, Precision?</p>
                {
                    display && (
                        <div className="rounded-md bg-black mt-5">
                            <div className="p-6 text-[18px] dark:border-dark dark:text-gray-300">Classification Report Metrics</div>
                            <div className="divide-y divide-white-light px-6 pb-4.5 dark:divide-dark">
                                {metricsData.map((metric) => (
                                    <div key={metric.id}>
                                        <div
                                            className={`flex cursor-pointer items-center justify-between gap-10 px-2.5 py-2 text-base font-semibold hover:text-primary dark:text-white dark:rounded-md dark:hover:text-primary ${active1 === metric.id ? 'bg-primary-light dark:bg-[#1B2E4B] !text-primary' : ''}`}
                                            
                                            onClick={() => toggleActive(metric.id)}
                                        >
                                            <span>{metric.title}</span>
                                            {active1 !== metric.id ? (
                                                <span className="shrink-0">
                                                    <IconPlusCircle duotone={false} />
                                                </span>
                                            ) : (
                                                <span className="shrink-0">
                                                    <IconMinusCircle fill={true} />
                                                </span>
                                            )}
                                        </div>
                                        <AnimateHeight duration={300} height={active1 === metric.id ? 'auto' : 0}>
                                            <div className="px-1 py-3 font-semibold text-gray-300">
                                                <p>{metric.description}</p>
                                                {metric.code && (
                                                    <div className="bg-gray-300 p-4 rounded-lg mb-4 mt-4">
                                                        <code className="text-gray-700">
                                                            {metric.code}
                                                        </code>
                                                    </div>
                                                )}
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                }
                <Table />
            </div>
        </div>
    );
};

export default MlAnalytics;
