import React from 'react';
import styles from './patners.module.scss';
import { family_bank, zindi_logo, kcb_logo, adanianlabs_logo, afyarekod_logo, sunculture_logo } from '../../../assets/landingPage/constants';

const patners = [kcb_logo, adanianlabs_logo, afyarekod_logo, sunculture_logo, family_bank, zindi_logo];

const Patners = () => {
    return (
        <div className={styles.container}>
            <div className={styles.text_container}>
                <p>
                    Join 45+ financial institutions growing with <span>Caspre</span>
                </p>
            </div>

            <div className={styles.patners_container}>
                {patners.map((item, index) => (
                    <img key={index} src={item} alt="patner" />
                ))}
            </div>
        </div>
    );
};

export default Patners;
