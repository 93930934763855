import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navbar.module.scss';
import { Button } from '@mui/material';
import { caspre_logo } from '../../../../assets/landingPage/constants';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/auth/login')
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.container}>
            <img src={caspre_logo} alt="Logo" />
            <ul className={styles.navList}>
                <li>Home</li>
                <li>How it works</li>
                <li>About Us</li>
                <li>Resources</li>
                <li>Contact Us</li>
            </ul>
            <div className={styles.hamburger} onClick={toggleMenu}>
                {isOpen ? <CloseIcon /> : <MenuIcon />}
            </div>
            <Button className={styles.btn} onClick={handleClick}>Sign in</Button>
            {isOpen && (
                <div className={styles.overlay} onClick={toggleMenu}>
                    <ul className={styles.menu}>
                        <li>Home</li>
                        <li>How it works</li>
                        <li>About Us</li>
                        <li>Resources</li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Navbar;
