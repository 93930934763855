import React from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import data from '../data/roc.json'
import ROCCurve from './rocCurve';
import ConfusionMatrixTable from './confusionMatrix';
const Table = () => {
  const tableData = data.classification_report
  const headers = Object.keys(tableData[0]);
  const aucrocData = {
    "title": "area_under_curve (AUCROC)",
      "aucscore": 0.5182648401826484,
        "auc_curve": [
          {
            "false_positive_rate": 0,
            "true_positive_rate": 0
          },
          {
            "false_positive_rate": 0.045454545454545456,
            "true_positive_rate": 0.0593607305936073
          },
          {
            "false_positive_rate": 0.045454545454545456,
            "true_positive_rate": 0.0684931506849315
          },
          {
            "false_positive_rate": 0.045454545454545456,
            "true_positive_rate": 0.0730593607305936
          },
          {
            "false_positive_rate": 0.045454545454545456,
            "true_positive_rate": 0.0821917808219178
          },
          {
            "false_positive_rate": 0.09090909090909091,
            "true_positive_rate": 0.1050228310502283
          },
          {
            "false_positive_rate": 0.09090909090909091,
            "true_positive_rate": 0.1095890410958904
          },
          {
            "false_positive_rate": 0.18181818181818182,
            "true_positive_rate": 0.2237442922374429
          },
          {
            "false_positive_rate": 0.2727272727272727,
            "true_positive_rate": 0.3424657534246575
          },
          {
            "false_positive_rate": 0.2727272727272727,
            "true_positive_rate": 0.3470319634703196
          },
          {
            "false_positive_rate": 0.2727272727272727,
            "true_positive_rate": 0.3789954337899543
          },
          {
            "false_positive_rate": 0.4090909090909091,
            "true_positive_rate": 0.5251141552511416
          },
          {
            "false_positive_rate": 0.4090909090909091,
            "true_positive_rate": 0.5296803652968036
          },
          {
            "false_positive_rate": 0.4090909090909091,
            "true_positive_rate": 0.5388127853881278
          },
          {
            "false_positive_rate": 0.5,
            "true_positive_rate": 0.54337899543379
          },
          {
            "false_positive_rate": 0.5454545454545454,
            "true_positive_rate": 0.5662100456621004
          },
          {
            "false_positive_rate": 0.5909090909090909,
            "true_positive_rate": 0.6073059360730594
          },
          {
            "false_positive_rate": 0.6363636363636364,
            "true_positive_rate": 0.6210045662100456
          },
          {
            "false_positive_rate": 0.7272727272727273,
            "true_positive_rate": 0.7031963470319634
          },
          {
            "false_positive_rate": 0.7727272727272727,
            "true_positive_rate": 0.7214611872146118
          },
          {
            "false_positive_rate": 0.8636363636363636,
            "true_positive_rate": 0.776255707762557
          },
          {
            "false_positive_rate": 0.8636363636363636,
            "true_positive_rate": 0.7853881278538812
          },
          {
            "false_positive_rate": 0.8636363636363636,
            "true_positive_rate": 0.8036529680365296
          },
          {
            "false_positive_rate": 0.9090909090909091,
            "true_positive_rate": 0.8082191780821918
          },
          {
            "false_positive_rate": 0.9090909090909091,
            "true_positive_rate": 0.8264840182648402
          },
          {
            "false_positive_rate": 0.9090909090909091,
            "true_positive_rate": 0.8310502283105022
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.863013698630137
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.8812785388127854
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.8995433789954338
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.908675799086758
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9315068493150684
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9360730593607306
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9497716894977168
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.954337899543379
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9634703196347032
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9680365296803652
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9771689497716894
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9817351598173516
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9908675799086758
          },
          {
            "false_positive_rate": 0.9545454545454546,
            "true_positive_rate": 0.9954337899543378
          },
          {
            "false_positive_rate": 1,
            "true_positive_rate": 1
          }
        ]
  }
  const confusionMatrixData = {
    title: "confusion_matrix",
    y: "predicted",
    x: "actual",
    row1: {
      "default": 3,
      "approved": 19
    },
    row2: {
      "default": 49,
      "approved": 170
    }
  };
  return (
    <div className='mt-5 mx-auto 2xl:max-w-[90%]'>
      <p className='text-center text-[18px] mb-5 font-medium'>Model Performance Metrics</p>
      <div className="table-responsive mb-5">
    <table className="table-hover">
          <thead>
            <tr className=" text-white font-semibold capitalize dark:!bg-info/10">
              {headers.map((header, index) => (
                <th key={index} className=' xl:text-[16px] text-[14px] text-center'>{header.replace(/_/g, ' ')}</th>
              ))}
            </tr>
          </thead>
        <tbody>
            {tableData.map((data,index) => {
                return (
                <tr key={index} className='border border-black  font-medium w-full text-white-light'>
                  <td className='border border-black text-[15px] text-info text-center font-medium'>{data['labels']}</td>
                  <td className='border border-black text-[15px] text-info text-center font-medium'>{data['precision'].toFixed(2)}</td>
                  <td className='text-[15px] border border-black text-info text-center font-medium'>{data['recall'].toFixed(2)}</td>
                  <td className='border border-black text-[15px] text-info text-center font-medium'>{data['f1-score'].toFixed(2)}</td>
                </tr>
                );
            })}
          </tbody>
        </table>
      </div>
      <div className='grid grid-cols-2 mt-10 gap-5'>
        <ROCCurve aucrocData={aucrocData} />
        <ConfusionMatrixTable confusionMatrixData={confusionMatrixData} />
      </div>
    </div>
  )
}

export default Table