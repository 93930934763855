import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setPageTitle } from '../../../store/themeConfigSlice';
import { useDispatch,useSelector } from 'react-redux';
import IconHome from '../../../components/Icon/IconHome';
import IconUsersGroup from '../../../components/Icon/IconUsersGroup';
import SingleUpload from './SingleUpload';
import PredictUploadComponent from '../../../components/upload/PredictUploadComponent';
import UserManagement from './UserManagement';
import Predictions from './Predictions';
import Header from './Header';
const PredictPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Predict Page'));
    });
    const [tabs, setTabs] = useState<string>('single-upload');
    const [isfileUpload, setIsFileUpload] = useState<boolean>(false);
    const toggleTabs = (name: string) => {
        setIsFileUpload(false)
        setTabs(name);
    };
    const user = useSelector((state: any) => state.user);
    console.log(user)
    const onFileUpload = (data: any) => { 
        setIsFileUpload(true)
    }
    console.log(isfileUpload)
    return (
        <div className='p-10'>
            <ul className="flex justify-between items-center rounded-md px-3 w-full bg-black">
                <li>
                    <Link to="#" className="text-primary hover:underline">
                        Predict Customer Credit Score
                    </Link>
                </li>
                <Header />
            </ul>
            <div className="pt-5">
                <div>
                    <ul className="sm:flex font-semibold border-b border-[#ebedf2] dark:border-[#191e3a] mb-5 whitespace-nowrap overflow-y-auto space-x-10">
                        <li className="inline-block">
                            <button
                                onClick={() => toggleTabs('single-upload')}
                                className={`flex gap-2 p-4 border-b border-transparent hover:border-primary hover:text-primary ${tabs === 'single-upload' ? '!border-primary text-primary' : ''}`}
                            >
                                <IconHome />
                                Single Upload
                            </button>
                        </li>
                        <li className="inline-block">
                            <button
                                onClick={() => toggleTabs('multiple-upload')}
                                className={`flex gap-2 p-4 border-b border-transparent hover:border-primary hover:text-primary ${tabs === 'multiple-upload' ? '!border-primary text-primary' : ''}`}
                            >
                                <IconUsersGroup className="w-5 h-5" />
                                Multiple Upload
                            </button>
                        </li>
                        <li className="inline-block">
                            <button
                                onClick={() => toggleTabs('predictions')}
                                className={`flex gap-2 p-4 border-b border-transparent hover:border-primary hover:text-primary ${tabs === 'predictions' ? '!border-primary text-primary' : ''}`}
                            >
                                <IconUsersGroup className="w-5 h-5" />
                                Predictions
                            </button>
                        </li>
                    </ul>
                </div>
                {tabs === 'single-upload' ? (
                <SingleUpload />
                ) : (
                    ''
                )}
                {tabs === 'multiple-upload' ? (
                    isfileUpload ? (
                        <UserManagement />
                    ) : (
                            <PredictUploadComponent onFileUpload={onFileUpload} setIsFileUpload={setIsFileUpload} user={user} />
                        )
                ) : (
                    ''
                )}
                {tabs === 'predictions' ? (
                < Predictions/>
                ) : (
                    ''
                )}


            </div>
        </div>
    );
};

export default PredictPage;
