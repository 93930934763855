import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { AiOutlineLeft } from 'react-icons/ai';
import { AiOutlineRight } from 'react-icons/ai';
const TableData = ({ numbersummary }: { numbersummary: any[] }) => {

    // Extract table headers from the keys of the first object in the numbersummary array
    const headers = Object.keys(numbersummary[0]);

    return (
        <div className="">
            <div className=" p-4">
                <div className="table-responsive text-gray-400 bg-black/20 p-5">
                    <h1 className="font-bold text-lg mb-4 text-gray-200 text-center">Summary Table</h1>
                    <table className="table-hover bg-black-dark-light w-full">
                        <thead className='bg-primary/5'>
                            <tr className=" text-info font-semibold dark:!bg-black-dark-light rounded-md">
                                {headers.map((header, index) => (
                                    <th key={index} className=" xl:text-[16px] text-[14px] uppercase text-center">
                                        {header.replace(/_/g, ' ')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {numbersummary.map((row) => (
                                <tr key={row.id} className=" font-medium w-full">
                                    {headers.map((header, index) => (
                                        <td key={index} className={` text-[14px] text-gray-200 border border-black text-center`}>
                                            {typeof row[header] === 'number' ? row[header].toFixed(2) : row[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* <ul className="flex items-center justify-center  space-x-1 rtl:space-x-reverse m-auto border border-gray-800 p-4 ">
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                {' '}
                                <AiOutlineLeft />
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:hover:bg-primary dark:bg-primary"
                            >
                                1
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                2
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                3
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                . . .
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold px-3.5 py-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                5
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white dark:bg-[#191e3a] dark:hover:bg-primary"
                            >
                                <AiOutlineRight />
                            </button>
                        </li>
                    </ul> */}
                </div>
            </div>
        </div>
    );
};

export default TableData;
