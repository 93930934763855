import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HeroSectionStyles.module.scss';
import Navbar from './Navbar/Navbar';
import { Button } from '@mui/material';
import { bank_card } from '../../../assets/landingPage/constants/index';

const HeroSection = () => {
    const navigate = useNavigate();
    const handleGetStarted = () => {
        navigate('/auth/login');
    };
    const handleLearnMore = () => {
        navigate('/knowledge');
    };
    return (
        <div className={styles.hero_section_container}>
            <div>
                <Navbar />
            </div>
            <div className={styles.cta_container}>
                <div className={styles.cta_text_container}>
                    <p className={styles.cta}>Transform Your Credit Future with Caspre's Cutting-Edge AI</p>

                    <p className={styles.sub_cta}>
                        Utilize advanced AI for unmatched accuracy in credit scoring, unlock comprehensive insights, and elevate your lending decisions to improve risk management and increase
                        profitability.
                    </p>

                    <div className={styles.btn_container}>
                        <Button className={styles.btn1} onClick={handleGetStarted}>
                            Get Started
                        </Button>
                        <Button className={styles.btn2} onClick={handleLearnMore}>
                            Learn More
                        </Button>
                    </div>
                </div>

                <img src={bank_card} className={styles.image} />
            </div>
        </div>
    );
};

export default HeroSection;
