import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { setPageTitle } from '../../store/themeConfigSlice';
import { useDispatch, useSelector } from 'react-redux';
import IconBell from '../../components/Icon/IconBell';
import IconXCircle from '../../components/Icon/IconXCircle';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { findUpload, getUserUploadStatus, setUserUploadStatus, getBinaries, uploadFileToServer, InitiateTraining, advancedAnalytics } from '../../services/apiService';
import { updateFile } from '../../store/fileSlice';
import CountUp from 'react-countup';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondComponent from '../../components/upload/FilePondComponent';
import FileTable from '../../components/Tables/FileTable';
import AdvancedTabs from '../../components/upload/AdvancedTabs';
import Progress from '../../components/upload/Progress';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface FileInfo {
    serverID: string;
    filename: string;
    headers: any;
    fileType: string;
    uploadedAt: string;
    id: string;
    fileTrained: String;
    originalName: String;
    fileUploadedToServer: String;
    fileTableReference: String;
    fileTrainingComplete: boolean;
}

interface userUploadStatus {
    status: boolean;
    data: any;
}

interface AdvancedAnalyticsData {
    numbercorrelation: any;
    featuresignificance: any;
}

const Upload = () => {
    const dispatch = useDispatch();
    const [serverID, setServerID] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
    const [advancedAnalyticsData, setAdvancedAnalyticsData] = useState<AdvancedAnalyticsData>({ numbercorrelation: [], featuresignificance: [] });
    const [fileTrained, setFileTrained] = useState(false);
    const [fileTrainingCompleted, setFileTrainingCompleted] = useState(false);
    const [fileUploadedToServer, setFileUploadedToServer] = useState(false);
    const [binariesLoaded, setBinariesLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tvsUploadLoading, setTvsUploadLoading] = useState(false);
    const [binaries, setBinaries] = useState([]);
    const [approvedBinary, setApprovedBinary] = useState('');
    const [rejectBinary, setRejectedBinary] = useState('');
    const [isUploadValid, setIsUploadValid] = useState(false);
    const [targetVariable, setTargetVariable] = useState('');

    //const filePondRef = useRef<FilePond>();
    const user = useSelector((state: any) => state.user);

    useEffect(() => {
        dispatch(setPageTitle('File Upload Preview'));
    });

    /* useEffect(() => {
        const fetchFileInfo = async () => {
            try {
                await findAPIUpload();
                localStorage.setItem('fileInfo', JSON.stringify(fileInfo));
            } catch (error) {
                
            }
        };

        fetchFileInfo();
    }, []); */

    const onFileUpload = async (serverID: string) => {
        setServerID(serverID);
        await findAPIUpload(serverID);

        await uploadAPIstatusUpdate();

        setTimeout(() => {
            setFileUploaded(true);
        }, 3000);
    };

    const targetVariableChanged = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value: targetVariable } = e.target;

        await getAPIBinaries(targetVariable);
        setTargetVariable(targetVariable);
    };

    useEffect(() => {
        validateBinaries();
    }, [rejectBinary, approvedBinary]);

    const approvedBinaryChanged = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;

        setApprovedBinary(value);
        validateBinaries();
    };

    const rejectedBinaryChanged = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;

        setRejectedBinary(value);
        validateBinaries();
    };

    const getAPIBinaries = async (column: string) => {
        setLoading(true);
        try {
            const filename = fileInfo?.filename;
            const filetype = fileInfo?.fileType;
            const getBinariesReqObj = {
                filename: filename,
                column: column,
                filetype: filetype,
            };

            const getBinariesFromAPI = await getBinaries(getBinariesReqObj);
            setLoading(false);

            if (getBinariesFromAPI) {
                setBinaries(getBinariesFromAPI);
                setBinariesLoaded(true);
            }
            return;
        } catch (error) {
            setLoading(false);
            return null;
        }
    };

    const uploadAPIstatusUpdate = async () => {
        try {
            const userID = await user.user.id;
            const uploadStatusReqObj = {
                userId: userID,
                status: true,
            };

            const setStatus = await setUserUploadStatus(uploadStatusReqObj);

            return;
        } catch (error) {
            return null;
        }
    };

    const findAPIUpload = async (serverID: string) => {
        try {
            const reqObj = {
                serverID: serverID,
            };
            const foundFile = await findUpload(reqObj);
            console.log('foundFile: ', foundFile);

            setFileInfo(foundFile);
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const fetchUploadStatus = async () => {
            await getUploadStatus();
        };
        console.log('usf fired');

        fetchUploadStatus();
    }, [fileUploadedToServer, fileTrained, fileTrainingCompleted]);

    const getUploadStatus = async () => {
        try {
            const userId = await user.user.id;

            const userUploadStatus: userUploadStatus = await getUserUploadStatus(userId);
            console.log('userUploadStatus: ', userUploadStatus);

            //setUserUploadStatus(userUploadStatus);
            setFileUploaded(userUploadStatus.status);
            setFileInfo(userUploadStatus.data);
            setFileUploadedToServer(userUploadStatus.data.fileUploadedToServer);
            setFileTrained(userUploadStatus.data.fileTrained);

            const fileData = userUploadStatus.data;
            console.log('fileInfo: ', fileInfo);
            if (userUploadStatus.data.fileUploadedToServer && fileData) {
                console.log('advanced init');
                await getAdvancedAnalytics(fileData);
            }
        } catch (error) {
            return null;
        }
    };

    const validateBinaries = () => {
        const validateApproved = approvedBinary !== '' ? true : false;

        const validateRejected = rejectBinary !== '' ? true : false;

        const isBinariesSame = rejectBinary === approvedBinary;

        const validator = validateApproved && validateRejected;
        setIsUploadValid(validator);
    };

    const uploadFileToAPIServer = async () => {
        const reqObject = {
            filename: fileInfo?.filename,
            targetname: targetVariable,
            rejectname: rejectBinary,
            approvename: approvedBinary,
            userID: user.user.id,
            fileID: fileInfo?.id,
        };

        try {
            const uploadResponse = await uploadFileToServer(reqObject);

            setTimeout(() => {
                setFileUploadedToServer(true);
            }, 2000);
        } catch (error) {}
    };

    const initiateAPITraining = async () => {
        const reqObject = {
            fileID: fileInfo?.id,
            companyID: user.user.organisationID,
            tableName: fileInfo?.fileTableReference,
        };

        try {
            const initiateResponse = await InitiateTraining(reqObject);

            setTimeout(() => {
                setFileTrained(true);
            }, 2000);
        } catch (error) {}
    };

    const submitToServer = async () => {
        console.log('submit fired');
        setTvsUploadLoading(true);
        await uploadFileToAPIServer();
        setTvsUploadLoading(false);

        await getAdvancedAnalytics(fileInfo);
    };

    const reUpload = () => {
        //setFileTrained(false);
        setFileUploaded(false);
        //setFileUploadedToServer(false);
        //setFileTrainingCompleted(false);
        setFileInfo(null);
    };

    const getAdvancedAnalytics = async (fileData: any) => {
        console.log('adv fired');
        const reqObject = {
            fileID: fileData?.id || 'test',
            companyID: user.user.organisationID,
            tableName: fileData?.fileTableReference,
        };

        try {
            const advancedAnalyticsResponse: AdvancedAnalyticsData = await advancedAnalytics(reqObject);
            console.log('advancedAnalyticsResponse: ', advancedAnalyticsResponse);

            setTimeout(() => {
                setAdvancedAnalyticsData(advancedAnalyticsResponse);

                console.log('advancedAnalytics: ', advancedAnalyticsData);
            }, 1000);
        } catch (error) {}
    };

    return (
        <div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="#" className="text-primary hover:underline">
                        Credit scoring
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Data Upload</span>
                </li>
            </ul>
            <div className="pt-5 space-y-8">
                <div className="panel p-3 flex items-center text-primary overflow-x-auto whitespace-nowrap">
                    <div className="ring-2 ring-primary/30 rounded-full bg-primary text-white p-1.5 ltr:mr-3 rtl:ml-3">
                        <IconBell />
                    </div>
                    <span className="ltr:mr-3 rtl:ml-3">Guide: </span>
                    <p>Upload your historical data records</p>
                </div>
            </div>
            <div className="flex gap-6 mt-6">
                <div className={fileInfo ? 'panel w-3/4' : 'panel w-full'}>
                    <div className={fileUploaded ? 'hidden' : 'w-full'}>
                        <div className="flex justify-between">
                            <span className="text-primary font-bold text-lg">Upload</span>
                            <span className="text-primary font-bold text-lg">
                                <IconXCircle />
                            </span>
                        </div>
                        <div className="w-full mt-6">
                            <FilePondComponent onFileUpload={onFileUpload} user={user.user} />
                        </div>
                    </div>
                    <div className={!fileUploaded ? 'hidden' : 'w-full'}>
                        <div className={!fileUploadedToServer ? 'hidden' : 'w-full'}>
                            <div className="w-full">
                                <div className="">
                                    <span className="text-primary font-bold text-lg">Initiate model training</span>
                                </div>
                                <div className="py-4 px-4 w-full">
                                    <AdvancedTabs data={advancedAnalyticsData} fileInfo={fileInfo} reUpload={reUpload} />
                                </div>
                            </div>
                        </div>
                        <div className={fileUploadedToServer ? 'hidden' : 'w-full'}>
                            <div className="">
                                <span className="text-primary font-bold text-lg">Target variable setting</span>
                            </div>
                            <div className="p-4 w-full">
                                <div className="space-y-5">
                                    <div className="">
                                        <div>
                                            <label>Select your target variable</label>
                                            <select disabled={loading} className="form-select text-white-dark" onChange={targetVariableChanged}>
                                                <option disabled selected>
                                                    Choose...
                                                </option>
                                                {fileInfo && fileInfo.headers
                                                    ? fileInfo.headers.map((header: string, index: any) => (
                                                          <option key={index} value={header}>
                                                              {header}
                                                          </option>
                                                      ))
                                                    : ''}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={binariesLoaded ? 'grid grid-cols-1 sm:grid-cols-2 gap-4' : 'hidden'}>
                                        <div>
                                            <label>Label for approved</label>
                                            <select className="form-select text-white-dark" onChange={approvedBinaryChanged}>
                                                <option disabled selected>
                                                    Choose...
                                                </option>
                                                {binaries &&
                                                    binaries.map((binary: string, index: any) => (
                                                        <option key={index} value={binary}>
                                                            {binary}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="gridPassword">Label for rejected</label>
                                            <select className="form-select text-white-dark" onChange={rejectedBinaryChanged}>
                                                <option disabled selected>
                                                    Choose...
                                                </option>
                                                {binaries &&
                                                    binaries.map((binary: string, index: any) => (
                                                        <option key={index} value={binary}>
                                                            {binary}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    {tvsUploadLoading ? (
                                        <button disabled={true} type="submit" className={binariesLoaded ? '!mt6 btn btn-primary' : 'hidden'}>
                                            Uploading...
                                        </button>
                                    ) : (
                                        <button disabled={!isUploadValid} onClick={submitToServer} type="submit" className={binariesLoaded ? 'btn btn-primary !mt-6' : 'hidden'}>
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={fileInfo ? 'w-1/4 panel' : 'hidden'}>
                    <div className="">
                        <span className="text-primary font-bold text-lg">{fileTrained ? 'Trained File' : 'Uploaded file'}</span>
                    </div>
                    <div className="mt-4 w-full">
                        <Progress onTrainingComplete={() => setFileTrainingCompleted(true)} companyID={user.user.organisationID} fileInfo={fileInfo} trainingInitiate={initiateAPITraining} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Upload;
