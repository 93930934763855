import { combineReducers, configureStore } from '@reduxjs/toolkit';
import themeConfigSlice from './themeConfigSlice';
import authSlice, { loadTokensFromStorage } from './authSlice';
import userSlice from './userSlice';
import fileSlice from './fileSlice';
import fileUploadSlice from './fileUploadSlice';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    authentication: authSlice,
    user: userSlice,
    file: fileSlice,
    fileUpload:fileUploadSlice
});
const store = configureStore({
    reducer: rootReducer,
});

store.dispatch(loadTokensFromStorage());

export default store;

export type IRootState = ReturnType<typeof rootReducer>;
