import React from 'react';
import styles from './footer.module.scss';
import { caspre_logo } from '../../../assets/landingPage/constants';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { Button } from '@mui/material';

const footerLinks = [
    { title: `Explore`, links: [{ item: `Features`, href: '#features' }, { item: 'Testimonials', href: '#testimonials' }, { item: `Track Record`, href: '#track_record' }, { item: `Value Proposition`, href: '#value_proposition' }] },
    { title: `Patners`, links: [{ item: `Adanian Labs` }, { item: `AICE Africa` }] },
    { title: `Legal`, links: [{ item: `Cookie Policy` }, { item: `Privacy Policy` }, { item: 'Terms and Conditions' }] },
];

const caspreInfo = [
    { icon: <LocationOnOutlinedIcon />, text: `Adanian Labs, Mkungu Close, westlands` },
    { icon: <MailOutlinedIcon />, text: `info@caspre.io` },
    { icon: <CallOutlinedIcon />, text: `+2547 23 444 124` },
];

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.caspre_container}>
                <div className={styles.caspre}>
                    <img src={caspre_logo} alt="caspre logo" />
                    <p className={styles.desc}>
                        <strong>Availability:</strong> Monday to Friday, 8:00 am to 5:00 pm
                    </p>

                    {caspreInfo.map((item, index) => (
                        <div key={index} className={styles.info_container}>
                            {item.icon}
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>

                <div className={styles.footerLinks_container}>
                    {footerLinks.map((item, index) => (
                        <div key={index} className={styles.footer_link}>
                            <h1>{item.title}</h1>
                            <ul>
                                {item.links.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.href}>{link.item}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.newsletter_container}>
                <div className={styles.cta_container}>
                    <h1>Subscribe to our Newsletter</h1>
                    <p>Be on the know when we roll out new features.<br/> We promise we won&apos;t spam you</p>
                </div>

                <div className={styles.form_container}>
                    <form>
                        <input type="email" required placeholder="Email address" />
                        <Button className={styles.btn} type="submit">
                            Subscribe
                        </Button>
                    </form>
                    <p className={styles.newsletter_desc}>
                        By submitting your email address, you agree to receive Caspre&apos;s monthly newsletter. For more information, please read our privacy policy. You can always withdraw your
                        consent.
                    </p>
                </div>
            </div>

            <div className={styles.copyright_container}>
                <p>Copyright &copy; {new Date().getFullYear()} Caspre. All Rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;
