import React,{useState,useEffect} from 'react'
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { FaLocationDot } from "react-icons/fa6";
import 'chart.js/auto';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store';
import IconX from '../Icon/IconX';
import IconXCircle from '../Icon/IconXCircle';
import { useLocation } from 'react-router-dom';
import IconArrowBackward from '../Icon/IconArrowBackward';
import IconArrowLeft from '../Icon/IconArrowLeft';

const tableData2 = [
  {
    id: 1,
    name: 'Investment A',
    type: 'Real Estate',
    location: 'New York',
    years:5,
    link: 'link'
  },
  {
    id: 2,
    name: 'Investment B',
    type: 'Real Estate',
    location: 'New York',
    years:2,
    link: 'link'
  },
  {
    id: 3,
    name: 'Investment C',
    type: 'Real Estate',
    location: 'New York',
    years:3,
    link: 'link'
  },
];

const EntityProfile = () => {
  const[expectedReturn, setExpectedReturn] = useState(15);
  const [risk, setRisk] = useState(10);
  const [riskRatio, setRiskRatio] = useState(1.5);
  const [selectedItem, setSelectedItem] = useState(null)
  const [showRecomendations, setShowRecommendations] = useState(false)
  const [showRisks, setShowRisks] = useState(false)
  const location = useLocation();
  const { report } = location.state || {};
  const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
  const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl' ? true : false;

  useEffect(() => {
    updateRiskRatio();
  }, [expectedReturn, risk]);

  const updateRiskRatio = () => {
    const newRiskRatio = (risk === 0) ? 0 : (expectedReturn / risk).toFixed(2);
    setRiskRatio(newRiskRatio);
  };

  const handleExpectedReturnChange = (event) => {
    setExpectedReturn(event.target.value);
  };

  const handleRiskChange = (event) => {
    setRisk(event.target.value);
  };

  const riskRatioData = {
    labels: ['Risk Ratio', 'Remaining'],
    datasets: [{
      data: [riskRatio, 3 - riskRatio],
      backgroundColor: ['#4caf50', '#ddd'],
    }],
  };

  const riskReturnData = {
    labels: ['Investment A', 'Investment B', 'Investment C'],
    datasets: [
      {
        label: 'Expected Return (max return 10)',
        data: [4, 6, 8],
        backgroundColor: '#4361ee',
      },
      {
        label: 'Risk (max risk 10)',
        data: [3, 3, 6],
        backgroundColor: '#e7515a',
      },
    ],
  };

  const riskReturnOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const initialTableData = [
    { id: 1, metric: 'Initial Investment Amount', value: `` },
    { id: 2, metric: 'Projected Revenue', value: `` },
    { id: 3, metric: 'Total Return', value: `` },
    { id: 4, metric: 'Projected ROI', value: `` },
    { id: 5, metric: '5-Year Return', value: `` },
    { id: 6, metric: 'Revenue Growth Rate', value: `` },
    { id: 7, metric: 'Gross Profit Margin', value: `` },
    { id: 8, metric: 'Operating Profit Margin', value: `` },
  ]
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [selectedFraction, setSelectedFraction] = useState('');
  const [tableData, setTableData] = useState(initialTableData);

  const calculateResults = (investmentAmount, selectedFraction) => {
    const investment = parseFloat(investmentAmount);

    if (isNaN(investment)) {
      return {
        projectedRevenue: 0,
        totalReturn: 0,
        projectedROI: 0,
        fiveYearReturn: 0,
        revenueGrowthRate: 0,
        grossProfitMargin: 0,
        operatingProfitMargin: 0,
      };
    }

    let projectedRevenue;
    switch (selectedFraction) {
      case 'half':
        projectedRevenue = investment / 2;
        break;
      case 'quarter':
        projectedRevenue = investment / 4;
        break;
      case 'full':
      default:
        projectedRevenue = investment;
        break;
    }

    const totalReturn = investment + projectedRevenue;
    const projectedROI = ((projectedRevenue) / investment) * 100;
    const fiveYearReturn = projectedROI * 5; 
    const revenueGrowthRate = projectedROI / 5; 
    const grossProfitMargin = (projectedRevenue / totalReturn) * 100;
    const operatingProfitMargin = grossxProfitMargin * 0.85;

    return {
      projectedRevenue,
      totalReturn,
      projectedROI,
      fiveYearReturn,
      revenueGrowthRate,
      grossProfitMargin,
      operatingProfitMargin,
    };
  };

  const updateTableData = (calculatedResults) => {
    setTableData([
      { id: 1, metric: 'Initial Investment Amount', value: `$${investmentAmount}` },
      { id: 2, metric: 'Projected Revenue', value: `$${calculatedResults.projectedRevenue.toFixed(2)}` },
      { id: 3, metric: 'Total Return', value: `$${calculatedResults.totalReturn.toFixed(2)}` },
      { id: 4, metric: 'Projected ROI', value: `${calculatedResults.projectedROI.toFixed(2)}%` },
      { id: 5, metric: '5-Year Return', value: `${calculatedResults.fiveYearReturn.toFixed(2)}%` },
      { id: 6, metric: 'Revenue Growth Rate', value: `${calculatedResults.revenueGrowthRate.toFixed(2)}%` },
      { id: 7, metric: 'Gross Profit Margin', value: `${calculatedResults.grossProfitMargin.toFixed(2)}%` },
      { id: 8, metric: 'Operating Profit Margin', value: `${calculatedResults.operatingProfitMargin.toFixed(2)}%` },
    ]);
  };

  useEffect(() => {
    if (investmentAmount !== '') {
      const calculatedResults = calculateResults(investmentAmount, selectedFraction);
      updateTableData(calculatedResults);
    } else {
      setTableData(initialTableData);
    }
  }, [investmentAmount, selectedFraction]);

  const handleInvestmentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setInvestmentAmount(e.target.value);
  const handleFractionChange = (e) => setSelectedFraction(e.target.value);
  const data = [
    {
      "summary": "AI Innovators Ltd. is a Nairobi-based startup specializing in developing and deploying cutting-edge artificial intelligence (AI) solutions for corporate entities, small and medium-sized enterprises (SMEs), and governmental organizations. The company aims to empower organizations to leverage AI for enhancing operational efficiency, improving decision-making, and driving innovation. With a strong team of AI experts and professionals, AI Innovators Ltd. is strategically located in Upper Hill, Nairobi, Kenya, to access a diverse talent pool and dynamic market.",
      "potential_client": [],
      "potential_risk": [
        {
          "risk": "Market Competition",
          "description": "The AI market is becoming increasingly competitive with various players entering the space. AI Innovators Ltd. needs to differentiate itself effectively to stand out."
        },
        {
          "risk": "Regulatory Changes",
          "description": "Potential regulatory changes in the AI industry could impact the operations and growth of AI Innovators Ltd. It's essential to stay updated and compliant with regulations."
        }
      ],
      "recommendation": [
        {
          "recommendation": "Strategic Partnerships",
          "description": "AI Innovators Ltd. should consider forming strategic partnerships with key industry players to enhance market reach and offer complementary services."
        },
        {
          "recommendation": "Continuous Innovation",
          "description": "To stay ahead in the competitive AI market, continuous innovation in AI solutions and services is crucial for AI Innovators Ltd."
        }
      ],
      "score": {
        "business_overview": {
          "item": "Business Overview",
          "score": 18,
          "description": "Strong business overview highlighting the mission, objectives, team, and market analysis."
        },
        "financial_health": {
          "item": "Financial Health",
          "score": 16,
          "description": "Sound financial projections indicating profitability within three years and initial funding secured through a mix of bootstrapping and angel investment."
        },
        "market_position": {
          "item": "Market Position",
          "score": 17,
          "description": "Well-positioned in a growing AI market in Kenya and East Africa with a focus on tailored solutions for various sectors."
        },
        "risk_factors": {
          "item": "Risk Factors",
          "score": 14,
          "description": "Identified risks include market competition and regulatory changes, which require proactive mitigation strategies."
        },
        "growth_potential": {
          "item": "Growth Potential",
          "score": 19,
          "description": "High growth potential due to the increasing demand for AI solutions, strong team expertise, and robust marketing and sales strategies."
        },
        "total_score": 84
      },
      "location": "Upper Hill, Nairobi, Kenya"
    }
  ];
  const handleShowReccomendation = () => { 
    setShowRecommendations(!showRecomendations)

  }
  const handleShowRisks = () => {
    setShowRisks(!showRisks)

  }
  const handleClose = () => {
    setSelectedItem(false);
  };

  const parts = report.summary.split('.');

  // The company name is the part before the first period
  const companyName = parts[0];
  const handleGoBack = () => {
    window.history.back();
  }
  const handleShowReason = (key, value) => { 
    setSelectedItem(key)
  }
  return (
    <div>
      <div onClick={()=>handleGoBack()}>
        <IconArrowBackward className='text-primary cursor-pointer w-7 h-7 text-[12px] rounded-full ' />
      </div>
      
      <div className='grid grid-cols-2'>
        <div>
          <div className='flex gap-5 mt-2'>
            <h2 className='text-[24px] text-info items-center gap-1'>{companyName}</h2>
            {/* <h3 className='text-gray-400'>Investment Type: Real Estate</h3> */}
            <h4 className='text-gray-200 flex items-center gap-2'><FaLocationDot className='text-info text-lg' />{report.location}</h4>
          </div>
          <div className='mt-7'>
            <p className='text-info text-[16px] flex items-center gap-1'><div className='w-8 h-[1px] bg-info'></div>Summary of the file</p>
            <p className='w-[95%] text-gray-300 tracking-wider mt-2 p-2'>{report.summary}</p>
          </div>
          <p className='text-info text-[18px] flex items-center gap-1 mt-5'><div className='w-8 h-[1px] bg-info'></div>Financial Metrics</p>
          <div className="table-responsive mb-5 w-[95%] mt-4">
            <form>
              <div className="grid grid-cols-1 sm:flex justify-between gap-5 mb-5">
                <input
                  type="text"
                  placeholder="Enter investment amount"
                  className="form-input placeholder:text-white-light"
                  value={investmentAmount}
                  onChange={handleInvestmentAmountChange}
                />
                <select
                  value={selectedFraction}
                  onChange={handleFractionChange}
                  className="form-select placeholder:text-white-light"
                >
                  <option value="full">Full Projected Revenue</option>
                  <option value="half">1/2 Projected Revenue</option>
                  <option value="quarter">1/4 Projected Revenue</option>
                </select>
              </div>
            </form>
            <table className="table-hover">
              <thead className=''>
                <tr className="border border-black font-semibold capitalize dark:!bg-black border-none">
                  <th className='text-center text-white'>Metric</th>
                  <th className='text-center text-white'>Value</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((data) => {
                  return (
                    <tr key={data.id}>
                      <td className='border border-black w-[50%]  text-center text-info text-[14px] '>
                        {data.metric}
                      </td>
                      <td className='border border-black text-center text-warning '>{data.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <p className='mt-14 text-info text-lg'>Criteria by which the business plan was analyzed </p>
          <div className='gap-5 w-[95%]'>
            <div className='flex items-center gap-2 mt-2'>
              <p className='text-[16px] text-gray-300'>Total Score</p>
              <p className='text-success text-base'>{report.score.total_score}/100</p>
            </div>
            <div className='flex justify-between border-b border-black py-3 px-2 mt-2 text-gray-300 capitalize'>
              <p className='w-40'>criterion</p>
              <p>score/20</p>
              <p>justification</p>
            </div>
            {Object.entries(report.score).map(([key, value]) => (
              key !== 'total_score' && value && value.item && value.score && value.description && (
                <div key={key} className='flex flex-col'>
                  {selectedItem === key && (
                    <p className='text-white-light mt-5 flex items-center justify-between'>
                      {value.description}
                      <button onClick={handleClose} className=''>
                        <IconX className='text-danger w-6 h-6' />
                      </button>
                    </p>
                  )}
                  <div className='p-2 border-b border-black w-full flex justify-between items-center mt-5'>
                    <p className='text-info text-[14px] w-32'>{value.item}</p>
                    <p className='text-info text-[15px] font-medium'>{value.score}</p>
                    <p className='cursor-pointer text-sm hover:text-info' onClick={() => handleShowReason(key,value)}>
                      view
                    </p>
                  </div>
                </div>
              )
            ))}
          </div>
          <div className='grid grid-cols-2 gap-5 mt-7 w-[95%]'>
            {/* <div className='p-2 rounded-md bg-black flex flex-col justify-center'>
            <p className='text-info text-center text-[16px]'>Mission of the Project</p>
            <div className='flex'>
              
              <p className='p-2 tracking-wide text-gray-300 text-[14px]'><BiSolidQuoteAltLeft className='text-info w-5 h-5 inline-flex mr-5' />To generate sustainable returns for our investors by strategically acquiring, managing, and optimizing real estate assets, while fostering community development and environmental responsibility.<BiSolidQuoteAltRight className='text-info w-5 h-5 inline-flex ml-5' /></p>
              
            </div>
          </div> */}
            {/* <div className=' bg-black p-3 rounded-md'>
            <p className='text-info font-medium text-center text-[16px] mt-2'>Main objectives of the project</p>
            <ul className='mt-2 text-gray-300 tracking-wide px-2'>
              <li className='py-[2px]'><span className='text-info'>1.</span> Generate sustainable returns for investors</li>
              <li className='py-[2px]'><span className='text-info'>2.</span> Strategically acquire, manage, and optimize real estate assets</li>
              <li className='py-[2px]'><span className='text-info'>3.</span> Foster community development and environmental responsibility</li>
            </ul>
          </div> */}
          </div>

        </div>
        <div>
          {/* <div className='grid grid-cols-1 gap-2'>
          <div className='p-5 rounded-md bg-black'>
            <p className='text-[18px] text-gray-300 tracking-wide'>Risk Score</p>
            <div className="flex items-center gap-2">
              <div className="w-[80%] h-2 bg-[#ebedf2] dark:bg-dark/40 rounded-full flex mt-1">
                <div className="bg-primary h-2 rounded-full rounded-bl-full w-3/4 text-center text-white text-xs"></div>
              </div>
              <h3 className="text-info font-medium text-base mt-[2px] flex justify-center items-center">7.5/10</h3>
            </div>
            <p className='text-[15px] text-danger tracking-wide mt-3'>Risk Factors</p>
            <ul className=' list-decimal list-inside text-gray-300'>
              <li className='flex justify-between w-[90%] text-[13px] py-1 text-left'>Market Volatility<span className='text-danger'>High</span> </li>
              <li className='flex justify-between w-[90%] text-[13px] py-1 text-left'>Credit Risk <span className='text-warning'>Medium</span> </li>
              <li className='flex justify-between w-[90%] text-[13px] py-1 text-left'>Liquidity Risk <span className='text-info'>Low</span> </li>
            </ul>
          </div>
        </div> */}
          <div>
            <div className=' mt-5 py-5'>
              <p className='text-info text-[18px] mb-5'>Similar Investments</p>
              <div className="table-responsive mb-5 mt-3 rounded-md">
                <table className="table-hover">
                  <thead className=''>
                    <tr className="border-b border-white font-semibold capitalize rounded-md dark:!bg-black/80">
                      <th className='text-info text-center border border-black rounded-md'>Name</th>
                      <th className='text-info text-center border border-black'>Type</th>
                      <th className='text-info text-center border border-black'>Location</th>
                      <th className='text-info text-center border border-black'>Business Age</th>
                      <th className='text-info text-center border border-black rounded-md'>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData2.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td className='border border-black text-[14px] text-center text-gray-200 '>
                            {data.name}
                          </td>
                          <td className='border border-black text-center text-gray-200'>{data.type}</td>
                          <td className='border border-black text-center text-gray-200'>{data.location}</td>
                          <td className='border border-black text-center text-gray-200'>{data.years}</td>
                          <td className='border border-black text-center text-info cursor-pointer'>{data.link}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

            </div>
            <div className="">
              <div className="chart-container mt-5">
                <p className='text-info text-[18px] mb-5'>Graph of Investment against Expected Return & Risk</p>
                <Bar data={riskReturnData} options={riskReturnOptions} />
              </div>
              <div className='grid grid-cols-2'>
                <div className='p-5 mt-10 w-[90%] ml-6'>
                  <p className='text-[18px] text-info mt-5'>Recommendations ({data[0].recommendation.length})</p>
                  <p className='text-[12px] text-white cursor-pointer' onClick={() => handleShowReccomendation()}>
                    {showRecomendations ? 'Hide' : 'Show'} Recommendations</p>
                  {
                    showRecomendations && (
                      report.recommendation.map((item, index) => (
                        <div key={index}>
                          <p className='text-[15px] text-info mt-5'>{index + 1}. {item.recommendation}</p>
                          <p className='text-gray-300 text-[14px] mt-3'>{item.description}</p>
                        </div>
                      ))
                    )
                  }

                </div>
                <div className='p-5 mt-10 w-[90%] ml-6'>
                  <p className='text-[18px] text-info mt-5'>Potential risks ({data[0].potential_risk.length})</p>
                  <p className='text-[12px] text-white cursor-pointer' onClick={() => handleShowRisks()}>{showRisks ? 'Hide' : 'Show'} Risks</p>
                  {
                    showRisks && (
                      report.potential_risk.map((item, index) => (
                        <div key={index}>
                          <p className='text-[15px] text-info mt-5'>{index + 1}. {item.risk}</p>
                          <p className='text-gray-300 text-[14px] mt-3'>{item.description}</p>
                        </div>
                      ))
                    )
                  }

                </div>
              </div>

              {/* <div className="sliders">
              <label htmlFor="expectedReturn">Expected Return (%):</label>
              <input
                type="range"
                id="expectedReturn"
                name="expectedReturn"
                min="0"
                max="30"
                value={expectedReturn}
                onChange={handleExpectedReturnChange}
              />
              <span id="expectedReturnValue">{expectedReturn}%</span>

              <label htmlFor="risk">Risk (Standard Deviation %):</label>
              <input
                type="range"
                id="risk"
                name="risk"
                min="0"
                max="30"
                value={risk}
                onChange={handleRiskChange}
              />
              <span id="riskValue">{risk}%</span>
            </div> */}
            </div>

          </div>
          {/* <ReactApexChart series={areaChart.series} options={areaChart.options} className="rounded-lg bg-white dark:bg-primary-dark-light overflow-hidden mt-5" type="area" height={300} /> */}
        </div>
      </div>
    </div>
    


  )
}

export default EntityProfile