import { Dispatch } from 'redux';
import { refreshTokens } from './apiService';
import { loginSuccess } from '../store/authSlice';
import store from '../store';

interface Tokens {
    access: {
        token: string;
        expires: string;
    };
    refresh: {
        token: string;
        expires: string;
    };
}

export async function checkAndRefreshTokens(tokens: Tokens, dispatch: Dispatch): Promise<boolean> {
    const currentTime = new Date().getTime();
    const accessTokenExpires = new Date(tokens.access.expires).getTime();

    if (currentTime < accessTokenExpires) {
        // Access token is still valid
        return true;
    }

    // Access token has expired; check refresh token
    const refreshTokenExpires = new Date(tokens.refresh.expires).getTime();

    if (currentTime >= refreshTokenExpires) {
        // Both access and refresh tokens have expired
        return false;
    }

    // Refresh token is still valid; attempt to refresh tokens
    try {
        const refreshedTokens = await refreshTokens({
            refreshToken: tokens.refresh.token,
        });

        if (refreshedTokens) {
            const tokensObject = {
                access: { token: refreshedTokens.access.token, expires: refreshedTokens.access.expires },
                refresh: { token: refreshedTokens.refresh.token, expires: refreshedTokens.refresh.expires },
            };
            store.dispatch(loginSuccess(tokensObject));

            //dispatch({ type: 'UPDATE_TOKENS', payload: refreshedTokens });

            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error occurred while refreshing tokens:', error);
        return false;
    }
}
