// components/SummaryCard.js
import React from 'react';

interface SummaryCardProps {
  title: string;
  value: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value }) => {
  return (
    <div className="bg-primary/5 text-white-light shadow-md rounded-lg p-4 m-2">
      <h3 className="text-base text-info font-semibold mb-2">{title}</h3>
      <p className="text-[16px]">{value}</p>
    </div>
  );
};

export default SummaryCard;
