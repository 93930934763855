import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUploadedEntitities, loadEntityReport } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';

const UploadedEntities = () => {
  const user = useSelector((state: any) => state.user);
  const [uploadedEntities, setUploadedEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchUploadedEntities = async () => {
    try {
      const response = await loadUploadedEntitities(user.user.id);
      setUploadedEntities(response);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadedEntities();
  }, [user]);

  const handleShowProfile = async (id: any) => {
    try {
      const response = await loadEntityReport(id);
      console.log('response', response);
      navigate('/entityscoring/profile', { state: { report: response } });
    } catch (error) {
      console.log('error', error);
    }
  };



  return (
    <div>
      <h1 className='text-lg text-primary'>Uploaded Entities</h1>
      {uploadedEntities.length > 0 ? (
        uploadedEntities.map((entity: any) => {
          const date = new Date(entity.uploadedAt);
          const formattedDate = date.toLocaleDateString();

          return (
            <div key={entity.id} className='mt-5 flex justify-between items-center bg-black rounded-lg'>
              <div className='p-5'>
                <p className='text-white text-base'>{entity.originalName}</p>
                <p className='text-info text-sm'>Uploaded At: {formattedDate}</p>
              </div>
              <button
                className='btn btn-outline-primary h-10 mr-10'
                onClick={() => handleShowProfile(entity.id)}
              >
                Show profile
              </button>
            </div>
          );
        })
      ) : (
          <div className='mt-2 text-white'>You haven't uploaded any entitites yet</div>
      )}
    </div>
  );
};

export default UploadedEntities;
