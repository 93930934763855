import React from 'react';

type ConfusionMatrixData = {
  title: string;
  y: string;
  x: string;
  row1: {
    default: number;
    approved: number;
  };
  row2: {
    default: number;
    approved: number;
  };
};

const ConfusionMatrixTable = ({ confusionMatrixData }: { confusionMatrixData: ConfusionMatrixData }) => {
  const { title, y, x, row1, row2 } = confusionMatrixData;
  const [display, setDisplay] = React.useState(false);
  const handleDisplay = () => {
    setDisplay(!display);
  }
  return (
    <div>
      <div className='flex items-center justify-between mt-8'>
        <h2 className='text-base uppercase text-info'>{title}</h2>
        <p className=' cursor-pointer font-medium' onClick={()=>handleDisplay()}>what is confusion_matrix?</p>
      </div>
      {
        display && (
          <div className='p-5 text-gray-300'>
            <p>A confusion matrix is a performance measurement tool for machine learning classification problems. It is a table that is often used to describe the performance of a classification model (or "classifier") on a set of test data for which the true values are known.</p>
            <p className='text-info font-medium uppercase mt-2'>key Terms:</p>
            <ul className=' list-decimal list-inside'>
              <li className='py-1'><strong className='text-info'>True Positive (TP)</strong> The number of instances that are positive and correctly classified as positive.</li>
              <li className='py-1'><strong className='text-info'>False Positive (FP)</strong> The number of instances that are negative but incorrectly classified as positive.</li>
              <li className='py-1'><strong className='text-info'>True Negative (TN)</strong> The number of instances that are negative and correctly classified as negative.</li>
              <li className='py-1'><strong className='text-info'>False Negative (FN)</strong> TThe number of instances that are positive but incorrectly classified as negative.</li>

            </ul>
          </div>
        )
      }

      <table style={{}} className={`${display ? 'mt-0' : 'mt-16'}`}>
        <thead>
          <tr className='rounded-md dark:!bg-primary-dark-light'>
            <th className='border border-black'></th>
            <th className='text-center border border-black text-white'>{`${x} Default`}</th>
            <th className='text-center border border-black text-white'>{`${x} Approved`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className='border border-black'>{`${y} Default`}</th>
            <td className='text-center border border-black'>{row1.default}</td>
            <td className='text-center border border-black'>{row1.approved}</td>
          </tr>
          <tr>
            <th className='border border-black'>{`${y} Approved`}</th>
            <td className='text-center border border-black'>{row2.default}</td>
            <td className='text-center border border-black'>{row2.approved}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ConfusionMatrixTable;
