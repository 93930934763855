import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileInfo } from '../types';

interface FileState {
    file: FileInfo | null;
}

const initialState: FileState = {
    file: null,
};

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        updateFile(state, action: PayloadAction<FileInfo>) {
            console.log('action: ', action);
            state.file = action.payload;
        },
        clearFile(state) {
            state.file = null;
        },
    },
});

export const { updateFile, clearFile } = fileSlice.actions;

export default fileSlice.reducer;
