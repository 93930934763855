import React, { useEffect, useState } from 'react'
import IconDownload from '../../../components/Icon/IconDownload';
import { predictions } from '../../../services/apiService';
const Predictions = () => {
  const [uploadedPredictions, setuploadedPredictions] = useState<any>([{}])

  const fetchUploadedReports = async () => {
    try {
      const response = await predictions();
      setuploadedPredictions(response)
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    fetchUploadedReports()
  }, [])
  console.log('uploadedPredictions', uploadedPredictions)

  return (
    <div>
      <p className='text-primary text-[18px] p-2'>Predictions</p>
      <p className='p-2 text-white-light'>This page allows a user to access all predictions that have been made on the previous tab</p>
      {
        uploadedPredictions.map((prediction: any) => {
          return (
            <div key={prediction.id} className='mt-5 p-5 flex flex-col bg-black rounded-lg'>
              <p className=' text-white text-base'>{prediction.originalName}</p>
              <p className='text-info text-sm'>Uploaded At: {prediction.uploadedAt}</p>

              
              {/* <button className='btn btn-outline-primary h-10 mr-10'>Show profile</button> */}
            </div>
          )
        })
      }

    </div>
  )
}

export default Predictions