import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './features.module.scss';
import { analytics_system, data_intergration, profile_creation, scoring_system } from '../../../assets/landingPage/constants';
import { Button } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const features = [
    {
        title: 'behavioural analytics system',
        cta: 'We customize credit solutions to match spending patterns.',
        desc: `Upload financial documents such as bank and mobile money statements, and analyze spending patterns to cluster users for tailored credit offerings. This system uses advanced
               algorithms to provide a deep understanding of customer behaviors, helping you make data-driven decisions.`,
        img: analytics_system,
    },
    {
        title: 'CREDIT PROFILE CREATION',
        cta: `Your customers' profiles are backed by facts`,
        desc: `Generate comprehensive credit profiles that summarize financial habits and credit history. This feature offers actionable financial advice and suggestions for improving credit scores, enabling you to support your clients more effectively.`,
        img: profile_creation,
    },
    {
        title: 'PSEUDO DATA INTEGRATION',
        cta: 'We Factor in Everything, Including Climate',
        desc: `Incorporate geolocation and external factors like climate and economic conditions into your risk assessments. By integrating regional data, this feature refines credit scoring, providing a more accurate and nuanced view of creditworthiness.`,
        img: data_intergration,
    },
    {
        title: 'ENTITY SCORING SYSTEM',
        cta: `We Evaluate Risk, So You Don't Have To`,
        desc: `Evaluate investment entities such as projects or companies with detailed risk vs. value analysis. Access comprehensive reports, including projected ROI and risk factors, to make informed investment decisions and optimize your portfolio.`,
        img: scoring_system,
    },
];

const Features = () => {
    const navigate = useNavigate();
    const handleLearnMore = () => {
        navigate('/knowledge');
    };
    return (
        <div className={styles.container} id="features">
            <div className={styles.feature_container}>
                <div className={styles.feature_content_container}>
                    <div>
                        <h2 className={styles.title}>{features[0].title}</h2>
                        <h1 className={styles.cta}>{features[0].cta}</h1>
                        <p className={styles.desc}>{features[0].desc}</p>

                        <Button className={styles.btn} onClick={handleLearnMore}>
                            Learn more <ArrowCircleRightOutlinedIcon />
                        </Button>
                    </div>
                </div>
                <div className={styles.image_container_right}>
                    <img src={features[0].img} />
                </div>
            </div>

            <div className={styles.feature_container}>
                <div className={styles.image_container}>
                    <img src={features[1].img} />
                </div>
                <div className={styles.feature_content_container}>
                    <div className={styles.content_container}>
                        <h2 className={styles.title}>{features[1].title}</h2>
                        <h1 className={styles.cta}>{features[1].cta}</h1>
                        <p className={styles.desc}>{features[1].desc}</p>

                        <Button className={styles.btn} onClick={handleLearnMore}>
                            Learn more <ArrowCircleRightOutlinedIcon />
                        </Button>
                    </div>
                </div>
            </div>

            <div className={styles.feature_container}>
                <div className={styles.feature_content_container}>
                    <div>
                        <h2 className={styles.title}>{features[2].title}</h2>
                        <h1 className={styles.cta}>{features[2].cta}</h1>
                        <p className={styles.desc}>{features[2].desc}</p>

                        <Button className={styles.btn} onClick={handleLearnMore}>
                            Learn more <ArrowCircleRightOutlinedIcon />
                        </Button>
                    </div>
                </div>
                <div className={styles.image_container_right}>
                    <img src={features[2].img} />
                </div>
            </div>

            <div className={styles.feature_container}>
                <div className={styles.image_container}>
                    <img src={features[3].img} />
                </div>
                <div className={styles.feature_content_container}>
                    <div className={styles.content_container}>
                        <h2 className={styles.title}>{features[3].title}</h2>
                        <h1 className={styles.cta}>{features[3].cta}</h1>
                        <p className={styles.desc}>{features[3].desc}</p>

                        <Button className={styles.btn} onClick={handleLearnMore}>
                            Learn more <ArrowCircleRightOutlinedIcon />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
