import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import { questions } from './questions';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import styles from './faqsStyles.module.scss';

const Faqs = () => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h1>Frequently asked questions</h1>
                <p>Clear Answers to Common Questions</p>
            </div>

            <div className={styles.accordion}>
                {questions.map(({ question, answer }, idx) => (
                    <Accordion
                        key={idx}
                        expanded={expanded === idx}
                        onChange={handleChange(idx)}
                        sx={{
                            p: 0.7,
                            marginBottom: '1rem',
                            backgroundColor: '#131F35',
                            borderColor: '#C5FFE2',
                            borderWidth: 0.5,
                            borderRadius: 2,
                            mt: 3,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={expanded === idx ? <RemoveOutlinedIcon className={styles.icon} /> : <AddOutlinedIcon className={styles.icon} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'none',
                                },
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'none',
                                },
                            }}
                        >
                            <p className={styles.question}>{question}</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className={styles.answer} dangerouslySetInnerHTML={{ __html: answer }} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

export default Faqs;
