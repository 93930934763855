import { useState, useRef } from 'react';
import IconXCircle from '../../../components/Icon/IconXCircle';
import IconDownload from '../../../components/Icon/IconDownload';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';

const ProfileDetails = ({ selectedUser, handleCloseProfile }: { selectedUser: any, handleCloseProfile: () => void }) => {
  const pdfRef = useRef();

  const getRiskLevel = (creditScore: number) => {
    if (creditScore >= 700) {
      return { level: 'Low Risk', className: 'text-green-500' };
    } else if (creditScore >= 500 && creditScore < 700) {
      return { level: 'Medium Risk', className: 'text-yellow-500' };
    } else {
      return { level: 'High Risk', className: 'text-red-500' };
    }
  };
  const { level, className } = getRiskLevel(selectedUser?.credit_score);
  const currentDate = new Date().toLocaleDateString();

  const handleDownload = async () => {
    const doc = new Document({
      creator: "Your App Name",
      title: "Customer Profile Details",
      description: "Generated customer profile details document",
      subject: "Customer Profile",
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun({ text: `Name: ${selectedUser?.name || "N/A"}` })],
            }),
            new Paragraph({
              children: [new TextRun({ text: `Credit score: ${selectedUser?.credit_score || "N/A"}` })],
            }),
            new Paragraph({
              children: [new TextRun({ text: `Last Updated: ${currentDate}` })],
            }),
            new Paragraph({
              children: [new TextRun({ text: `Risk Level: ${level}` })],
            }),
            new Paragraph({
              children: [new TextRun({ text: 'Positive Contributing Factors:' })],
            }),
            ...(selectedUser.contributingFactors?.positive?.map((factor: string) =>
              new Paragraph({
                children: [new TextRun({ text: `- ${factor}` })],
              })
            ) || [new Paragraph({
              children: [new TextRun({ text: `No positive contributing factors available.` })],
            })]),
            new Paragraph({
              children: [new TextRun({ text: 'Negative Contributing Factors:' })],
            }),
            ...(selectedUser.contributingFactors?.negative?.map((factor: string) =>
              new Paragraph({
                children: [new TextRun({ text: `- ${factor}` })],
              })
            ) || [new Paragraph({
              children: [new TextRun({ text: `No negative contributing factors available.` })],
            })]),
            new Paragraph({
              children: [new TextRun({
                text: 'This credit score indicates a mixed credit profile influenced by various factors. Positive aspects include consistent and timely payments. These factors generally contribute positively to a credit score by demonstrating financial discipline. The score is also affected by negative factors such as high credit utilization, recent instances of late payments. Recent late payments may indicate occasional financial challenges or difficulties meeting payment deadlines.'
              })],
            }),
            new Paragraph({
              children: [new TextRun({
                text: `Records found in the database (${selectedUser.sources?.length || 0}):`
              })],
            }),
            ...(selectedUser.sources?.map((record: string) =>
              new Paragraph({
                children: [new TextRun({ text: `- ${record}` })],
              })
            ) || [new Paragraph({
              children: [new TextRun({ text: `No records found in the database.` })],
            })]),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'ProfileDetails.docx');
  };

  const getCreditScoreCluster = (creditScore) => {
    if (creditScore >= 701) return 'A';  // Exceptional
    if (creditScore >= 601) return 'B';  // Very Good
    if (creditScore >= 501) return 'C';  // Good
    if (creditScore >= 400) return 'D';  // Fair
    if (creditScore >= 300) return 'E'
    return '';  // Poor
  };
  const mobileMoneyCount = selectedUser.sources?.mobileMoney?.length || 0;
  const bureauCount = selectedUser.sources?.bureau?.length || 0;
  const bankStatementsCount = selectedUser.sources?.bankStatements?.length || 0;
  const totalSourcesCount = mobileMoneyCount + bureauCount + bankStatementsCount;
  return (
    <div className="panel w-[40vw]">
      <button
        onClick={handleCloseProfile}
        className="absolute right-2 top-2"
      >
        <IconXCircle className="text-danger w-10 h-10" />
      </button>
      <button
        onClick={handleDownload}
        className="flex gap-1 items-center justify-center left-10 p-2 px-2 text-primary border border-primary rounded-md cursor-pointer group hover:text-white hover:bg-primary mb-5"
      >
        Download
        <IconDownload className="text-primary font-bold group-hover:text-white" />
      </button>
      <p className="font-semibold text-lg dark:text-white-light mb-5">
        Customer Profile Details
      </p>
      {Object.keys(selectedUser).length === 0 ? (
        <p>No customer was found with the corresponding id_number</p>
      ) : (
        <div className="" ref={pdfRef}>
          <p className="font-semibold text-info">
            {' '}
              <span className="text-[16px]">Name:</span> {selectedUser.name ? 'selectedUser.name':'no available name'}
          </p>
          <div className="mt-5 bg-dark-dark-light rounded-md py-3">
            <div className="p-5 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="mt-1">
                    Credit score:{' '}
                    <span className="font-bold text-[16px] pb-2">
                      {selectedUser?.credit_score}
                    </span>
                  </p>
                  <p className="mt-1">
                    Last Updated: <span className="text-info">{currentDate}</span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 2xl:grid-cols-3 justify-between w-full mt-3">
                <div className="py-3">
                  <p className="text-[14px] text-success">
                    Positive Contributing Factors
                  </p>
                  {selectedUser.contributingFactors?.positive?.length > 0 ? (
                    <ul className="list-disc list-inside mt-2 text-[14px] pl-5">
                      {selectedUser.contributingFactors.positive.map(
                        (factor: string, index: number) => (
                          <li key={index}>{factor}</li>
                        )
                      )}
                    </ul>
                  ) : (
                    <p className="text-[14px] mt-2">
                      No positive contributing factors available.
                    </p>
                  )}
                </div>
                <div className="mt-1">
                  <div className="flex gap-3 flex-col items-center justify-center">
                    <p className="text-[18px] font-semibold text-white-light">
                      Cluster
                    </p>
                    <p className="w-20 h-20 rounded-full border border-info text-xl capitalize font-bold text-info flex justify-center items-center text-center">
                        {getCreditScoreCluster(selectedUser?.credit_score)}
                    </p>
                    <p className={`mt-2 pl-2 text-center ${className}`}>
                      {level}
                    </p>
                  </div>
                </div>
                <div className="py-3">
                  <p className="text-danger text-[14px]">
                    Negative Contributing Factors
                  </p>
                  <ul className="list-disc list-inside pl-5 mt-2 text-[14px]">
                    {selectedUser.contributingFactors?.negative?.length > 0 ? (
                      <ul className="list-disc list-inside mt-2 text-[14px] pl-5">
                        {selectedUser.contributingFactors.negative.map(
                          (factor: string, index: number) => (
                            <li key={index}>{factor}</li>
                          )
                        )}
                      </ul>
                    ) : (
                      <p className="text-[14px] mt-2">
                        No negative contributing factors available.
                      </p>
                    )}
                  </ul>
                </div>
              </div>
              <p className="mt-5 font-light leading-6">
                This credit score indicates a mixed credit profile influenced by
                various factors. Positive aspects include consistent and timely
                payments. These factors generally contribute positively to a
                credit score by demonstrating financial discipline. The score is
                also affected by negative factors such as high credit
                utilization, recent instances of late payments. Recent late
                payments may indicate occasional financial challenges or
                difficulties meeting payment deadlines.
              </p>
            </div>
          </div>
          <p className="text-white-light mt-10 mb-2">
              Records found in the database ({totalSourcesCount})
          </p>
            <ul className="list-disc list-inside pl-2 mb-3">
              {/* Mobile Money Section */}
              {selectedUser.sources?.mobileMoney?.length > 0 && (
                <li>
                  <strong>Mobile Money:</strong>
                  <ul className="list-disc list-inside pl-2 mb-3">
                    {selectedUser.sources.mobileMoney.map((record: string, index: number) => (
                      <li key={`mobileMoney-${index}`}>{record}</li>
                    ))}
                  </ul>
                </li>
              )}

              {/* Bureau Section */}
              {selectedUser.sources?.bureau?.length > 0 && (
                <li>
                  <strong>Bureau:</strong>
                  <ul className="list-disc list-inside pl-2 mb-3">
                    {selectedUser.sources.bureau.map((record: string, index: number) => (
                      <li key={`bureau-${index}`}>{record}</li>
                    ))}
                  </ul>
                </li>
              )}

              {/* Bank Statements Section */}
              {selectedUser.sources?.bankStatements?.length > 0 && (
                <li>
                  <strong>Bank Statements:</strong>
                  <ul className="list-disc list-inside pl-2 mb-3">
                    {selectedUser.sources.bankStatements.map((record: string, index: number) => (
                      <li key={`bankStatements-${index}`}>{record}</li>
                    ))}
                  </ul>
                </li>
              )}

              {/* Fallback if no sources available */}
              {!(selectedUser.sources?.mobileMoney?.length > 0 || selectedUser.sources?.bureau?.length > 0 || selectedUser.sources?.bankStatements?.length > 0) && (
                <li>No sources available</li>
              )}
            </ul>

        </div>
      )}
    </div>
  );
};

export default ProfileDetails;
