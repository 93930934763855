import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import AdvancedAnalytics2 from './AdvancedAnalytics2';
import AdvancedAnalytics1 from './AdvancedAnalytics1';
import { FC } from 'react';
import AdvancedAnalytics3 from './AdvancedAnalytics3';
import FileTable from '../Tables/FileTable';

interface AdvancedDataProps {
    data?: AdvancedAnalyticsData;
    fileInfo: FileInfo | null;
    reUpload: () => void;
}

interface FileInfo {
    serverID: string;
    filename: string;
    headers: any;
    fileType: string;
    uploadedAt: string;
    id: string;
    fileTrained: String;
    originalName: String;
    fileUploadedToServer: String;
    fileTableReference: String;
}

interface AdvancedAnalyticsData {
    numbercorrelation: any;
    featuresignificance: any;
}

const AdvancedTabs: FC<AdvancedDataProps> = ({ data, fileInfo, reUpload }) => {
    return (
        <div className="mb-5 flex flex-col sm:flex-row">
            <div className="mb-5 w-full">
                <Tab.Group>
                    <Tab.List className="mt-3 flex flex-wrap justify-end border-b border-white-light dark:border-[#191e3a]">
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`${selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#191e3a] dark:!border-b-black' : ''}
                                                    dark:hover:border-b-black' -mb-[1px] block border border-transparent p-3.5 py-2 hover:border-white-light hover:border-b-white dark:hover:border-[#191e3a]`}
                                >
                                    Overview
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`${selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#191e3a] dark:!border-b-black' : ''}
                                                    dark:hover:border-b-black' -mb-[1px] block border border-transparent p-3.5 py-2 hover:border-white-light hover:border-b-white dark:hover:border-[#191e3a]`}
                                >
                                    Correlation
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`${selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#191e3a] dark:!border-b-black' : ''}
                                                    dark:hover:border-b-black' -mb-[1px] block border border-transparent p-3.5 py-2 hover:border-white-light hover:border-b-white dark:hover:border-[#191e3a]`}
                                >
                                    Significance (Bar)
                                </button>
                            )}
                        </Tab>
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`${selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#191e3a] dark:!border-b-black' : ''}
                                                    dark:hover:border-b-black' -mb-[1px] block border border-transparent p-3.5 py-2 hover:border-white-light hover:border-b-white dark:hover:border-[#191e3a]`}
                                >
                                    Significance (Line)
                                </button>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="active pt-5">
                                <div className="">
                                    <span className="text-primary font-bold text-lg">Upload breakdown</span>
                                </div>
                                <div className="py-4 px-20 w-full mt-12">
                                    <FileTable file={fileInfo} />
                                </div>
                                <div className="py-4 px-20 w-full mt-6 flex justify-end">
                                    <button onClick={reUpload} type="submit" className={true ? 'btn btn-primary !mt-6' : 'hidden'}>
                                        Re-Upload
                                    </button>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="pt-5">
                                <AdvancedAnalytics1 correlations={data?.numbercorrelation} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className=" pt-5">
                                <AdvancedAnalytics2 significance={data?.featuresignificance} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className=" pt-5">
                                <AdvancedAnalytics3 significance={data?.featuresignificance} />
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    );
};

export default AdvancedTabs;
