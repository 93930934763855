import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../store/themeConfigSlice';
import { useEffect, useState } from 'react';
import { getCreditProfilesForCustomers } from '../../services/apiService';
import CreditProfileSearch from '../../components/Behavioral/Search';
import ProfileBreakdown from '../../components/Behavioral/ProfileBreakdown';

const CreditProfiles = () => {
    const dispatch = useDispatch();
    const [customerData, setCustomerData] = useState<any>([{}])
    const [selectedProfile, setSelectedProfile] = useState({
        thumb: 'profile-15.jpeg',
        name: 'Jesse Cory',
        email: 'Jesse@mail.com',
        creditScore: 690,
        totalAccounts: 3,
        totalTransactions: 110,
        regularIncomeAmount: 2600,
        averageExpenditure: 1400,
        totalCreditRequests: 6,
        defaultRate: 2.8,
        averageAccountBalance: 4800,
        profileID: 'JC7654',
        profileIdentifier: '99874321',
        identifierType: 'KRA PIN',
    });

    useEffect(() => {
        dispatch(setPageTitle('Credit Profiles'));
    });

    const onProfileSelect = (profile: any) => {
        console.log('profile selected: ', profile);
        setSelectedProfile(profile);
    };
    const fetchAllCustomers = async () => {
        try {
            const response = await getCreditProfilesForCustomers();
            setCustomerData(response)
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        fetchAllCustomers()
    }, [])
    return (
        <div className="">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="#" className="text-primary hover:underline">
                        Behavioral
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Credit Profiles</span>
                </li>
            </ul>
            <div className="flex space-x-4">
                <div className="w-1/2">
                    <div className=" panel ">
                        <CreditProfileSearch CustomerData={customerData} onProfileSelect={onProfileSelect} />
                    </div>
                </div>
                <div className="w-1/2">
                    <ProfileBreakdown profile={selectedProfile} />
                </div>
            </div>
        </div>
    );
};

export default CreditProfiles;
