import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { setPageTitle } from '../../store/themeConfigSlice';
import { useEffect, useState } from 'react';
import CustomerData from '../../data/customers.json';
import IconCreditCard from '../../components/Icon/IconCreditCard';
import IconCalendar from '../../components/Icon/IconCalendar';
import IconDollarSign from '../../components/Icon/IconDollarSign';
import IconEye from '../../components/Icon/IconEye';
import { TbShieldStar } from 'react-icons/tb';
import { MdOutlineCalendarToday } from 'react-icons/md';
import IconMinus from '../../components/Icon/IconMinus';
import IconPlus from '../../components/Icon/IconPlus';
import ReactApexChart from 'react-apexcharts';
import { getCreditProfilesForCustomer } from '../../services/apiService';
import TransactionProfileBreakdown from '../../components/Behavioral/TransactionProfileBreakdown';
import { Tab } from '@headlessui/react';
import MpesaUploadComponent from '../../components/upload/MpesaUploadComponent';
import CrbUploadComponent from '../../components/upload/CrbUploadComponent';
import IconHome from '../../components/Icon/IconHome';
import { contributingFactors } from './contributingFactors';
interface Profile {
    thumb: string;
    name: string;
    email: string;
    creditScore: number;
    totalAccounts: number;
    totalTransactions: number;
    regularIncomeAmount: number;
    averageExpenditure: number;
    totalCreditRequests: number;
    defaultRate: number;
    averageAccountBalance: number;
    profileID: string;
    profileIdentifier: string;
    identifierType: string;
    cluster: string;

    
}

const ProfileBreakdownPage = () => {
    const dispatch = useDispatch();
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
    const [customerData, setCustomerData] = useState<any>([{}])
    const [isDataAvailable, setIsDataAvailable] = useState(false)
    const [positiveFactors, setPositiveFactors] = useState([]);
    const [negativeFactors, setNegativeFactors] = useState([]);
    const [deposits, setDeposits] = useState<any>([]);
    const [withdrawals, setWithdrawals] = useState<any>([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const profileId = queryParams.get('profileID');
    const user = useSelector((state: any) => state.user);

    useEffect(() => {
        dispatch(setPageTitle('Credit Profiles'));

        if (profileId) {
            const foundProfile = CustomerData.find((profile) => profile.profileID === profileId);
            if (foundProfile) {
                setSelectedProfile(foundProfile);
            }
        }
    }, [dispatch, profileId]);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const fetchCustomerData = async () => {
        try {
            const response = await getCreditProfilesForCustomer(user.user.id, profileId);
            console.log(response)

            for (const [monthNumber, { deposits: dep, withdrawals: withdr }] of Object.entries(response.mpesaStatementData.transactionCounts)) {
                const monthIndex = parseInt(monthNumber, 10) - 1;
                const month = monthNames[monthIndex];

                deposits.push({ month, count: dep });
                withdrawals.push({ month, count: withdr });
            }

            setCustomerData(response)
            setIsDataAvailable(true)
            generateRandomFactors()
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        fetchCustomerData()
    }, [dispatch, profileId])



    // if (!customerData) {
    //     return <div>Loading...</div>;
    // }

    const credit_facilities = [
        {
            "facility_type": "Loan",
            "facility": "Equity Bank",
            "amount": "200,000",
            "register_date": "2020-01-01",
            "maturity_date": "2025-01-01",
            "status": "Ongoing"
        },
        {
            "facility_type": "Credit Card",
            "facility": "Cooperative Bank",
            "amount": "100,000",
            "register_date": "2020-01-01",
            "maturity_date": "2025-01-01",
            "status": "Ongoing"
        }
    ]

    const noOfLoans = credit_facilities.length;
    const noOfOutstandingLoans = credit_facilities.filter((facility) => facility.status === 'Ongoing').length;
    const noOfFullfilledLoans = credit_facilities.filter((facility) => facility.status === 'Fulfilled').length;
    const noOFDefaultLoans = credit_facilities.filter((facility) => facility.status === 'Defaulted').length;
    const getCreditScoreCluster = (creditScore) => {
        if (creditScore >= 701) return 'A'; 
        if (creditScore >= 601) return 'B';
        if (creditScore >= 501) return 'C';  
        if (creditScore >= 400) return 'D';  
        if (creditScore >= 300) return 'E'
        return '';  // Poor
    };
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;

    const getRandomElements = (arr, count) => {
        const shuffled = arr.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    // Function to generate random factors
    const generateRandomFactors = () => {
        const randomPositiveFactors = getRandomElements(contributingFactors.positiveFactors, 3);
        const randomNegativeFactors = getRandomElements(contributingFactors.negativeFactors, 3);

        setPositiveFactors(randomPositiveFactors);
        setNegativeFactors(randomNegativeFactors);
    };
    return (
        <>
            {
                isDataAvailable?(
                    <div className="">
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="#" className="text-primary hover:underline">
                                    Behavioral
                                </Link>
                            </li>
                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Profile Analysis</span>
                            </li>
                        </ul>
                        <div className="flex space-x-4 mt-6">
                            <div className="text-white font-bold">{ }</div>
                        </div>
                        <div className='grid grid-cols-1  gap-10 mt-5'>
                            <div className="grid grid-cols-2 xl:grid-cols-4 gap-6 mb-6  h-full  rounded-md ">
                                <div className=' bg-black py-1 xl:w-[20vw] w-[40vw] h-28 flex flex-col rounded-lg'>
                                    <div className='flex justify-between p-2'>
                                        <TbShieldStar className='w-10 h-10 p-1 bg-info text-white-light  rounded-full text-sm ml-2 ' />
                                        <div className='flex flex-col gap-1 justify-center items-center px-2'>
                                            <p className='text-[16px]'>No of Loans</p>
                                            <p className='text-[20px] text-info mt-1 font-medium'>{noOfLoans}</p>
                                        </div>
                                    </div>
                                    <div className='border-t border-opacity-20 border-white-dark mt-1'>
                                        <p className='pl-2 py-2 flex justify-center items-center gap-2'><MdOutlineCalendarToday />Updated Today</p>
                                    </div>
                                </div>
                                <div className=' bg-black py-1 xl:w-[20vw] w-[40vw] h-28 flex flex-col rounded-lg'>
                                    <div className='flex justify-between p-2'>
                                        <TbShieldStar className='w-10 h-10 p-1 bg-primary text-white rounded-full text-sm ml-2 ' />
                                        <div className='flex flex-col gap-1 justify-center items-center px-2'>
                                            <p className='text-[16px]'>Outstanding Loans</p>
                                            <p className='text-[20px] text-primary mt-1 font-medium'>{noOfOutstandingLoans}</p>
                                        </div>
                                    </div>
                                    <div className='border-t border-opacity-20 border-white-dark mt-1'>
                                        <p className='pl-2 py-2 flex justify-center items-center gap-2'><MdOutlineCalendarToday />Updated Today</p>
                                    </div>
                                </div>
                                <div className=' bg-black py-1 xl:w-[20vw] w-[40vw] h-28 flex flex-col rounded-lg'>
                                    <div className='flex justify-between p-2'>
                                        <TbShieldStar className='w-10 h-10 p-1 bg-secondary text-white rounded-full text-sm ml-2 ' />
                                        <div className='flex flex-col gap-1 justify-center items-center px-2'>
                                            <p className='text-[16px]'>Loans Defaulted</p>
                                            <p className='text-[20px] text-secondary mt-1 font-medium'>{noOFDefaultLoans}</p>
                                        </div>
                                    </div>
                                    <div className='border-t border-opacity-20 border-white-dark mt-1'>
                                        <p className='pl-2 py-2 flex justify-center items-center gap-2'><MdOutlineCalendarToday />Updated Today</p>
                                    </div>
                                </div>
                                <div className=' bg-black py-1 xl:w-[20vw] w-[40vw] h-28 flex flex-col rounded-lg'>
                                    <div className='flex justify-between p-2'>
                                        <TbShieldStar className='w-10 h-10 p-1 bg-warning text-white rounded-full text-sm ml-2 ' />
                                        <div className='flex flex-col gap-1 justify-center items-center px-2'>
                                            <p className='text-[16px]'>Fulfilled Loans</p>
                                            <p className='text-[20px] text-warning mt-1 font-medium'>{noOfFullfilledLoans}</p>
                                        </div>
                                    </div>
                                    <div className='border-t border-opacity-20 border-white-dark mt-1'>
                                        <p className='pl-2 py-2 flex justify-center items-center gap-2'><MdOutlineCalendarToday />Updated Today</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-10 mt-5'>
                            <div className='panel'>
                                <p className='font-semibold mb-4 text-info text-lg'>Transaction Details</p>
                                <div className='grid grid-cols-2 w-full gap-5 mt-9'>
                                    <div>
                                        <p className='flex gap-2 text-[16px] text-white-light'><IconMinus className='w-5 h-5 text-danger bg-danger-dark-light rounded-full capitalize' /> Withdraw <span className='text-white-light'>Count</span> </p>
                                        <div className='months-container overflow-y-auto h-48 bg-dark-dark-light rounded-md mt-3'>
                                            {
                                                withdrawals.map((data) => (
                                                    <div className='flex justify-between'>
                                                        <p>{data.month}</p>
                                                        <p className='text-white-light'>{data.count}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <p className='flex gap-2 text-[16px] text-white-light' ><IconPlus className='w-5 h-5 text-success bg-success-dark-light rounded-full font-bold ' /> Deposit Count</p>
                                        <div className='months-container overflow-y-auto h-48 bg-dark-dark-light rounded-md mt-3'>
                                            {
                                                deposits.map((data) => (
                                                    <div className='flex justify-between'>
                                                        <p>{data.month}</p>
                                                        <p className='text-success'>{data.count}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='flex justify-between mt-5'>
                                        <p className='text-[15px] text-info'>Adjusted Score</p>
                                        <p className='text-success font-medium'>{customerData.mpesaStatementData.adjustedScore}</p>
                                    </div>
                                    <div className='flex justify-between mt-5'>
                                        <p className='text-[15px] text-info'>Final Score</p>
                                        <p className='text-success font-medium'>{customerData.mpesaStatementData.
                                            finalScore}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <p className='font-bold'>Credit Facilities</p>
                                <div className='overflow-y-auto h-96 credit_facilities'>
                                    {credit_facilities.map((data) => (
                                        <div className='bg-dark-dark-light mt-5'>
                                            <div className='flex gap-3 px-5 pt-5'>
                                                <div className="grid place-content-center w-9 h-9 rounded-md bg-secondary-light dark:bg-info text-secondary dark:text-secondary-light"><IconCreditCard /></div>
                                                <div>
                                                    <p className=' font-thin text-sm text-white-dark'>{data.facility_type}</p>
                                                    <p className='font-semibold text-[14px] text-white-light'>{data.facility}</p>
                                                </div>
                                            </div>
                                            <div className=' w-full h-20 rounded-md mt-5 px-3'>
                                                <div className='flex justify-around items-center'>
                                                    <div className='flex gap-3'>
                                                        <div>
                                                            <p className=' font-thin text-sm text-white-dark text-center'>Amount:</p>
                                                            <div className='flex gap-1 mt-[2px]'>
                                                                <IconDollarSign className='w-6 h-6 text-success bg-success-dark-light rounded-full' />
                                                                <p className='font-semibold text-[14px] flex  items-center'>{data.amount}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-[2px] h-14 bg-info opacity-55'></div>

                                                    <div className='flex gap-3'>
                                                        <div>
                                                            <p className=' font-thin text-sm text-white-dark text-center'>Register Date:</p>
                                                            <p className='font-semibold text-[14px] text-white-light text-center mt-[2px]'>{data.register_date}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-3'>
                                                        <div>
                                                            <p className=' font-thin text-sm text-white-dark text-center'>Maturity Date:</p>
                                                            <p className='font-semibold text-[14px] text-white-light text-center mt-[2px]'>{data.maturity_date}</p>
                                                        </div>
                                                    </div>
                                                    <div className='w-[2px] h-14 bg-info opacity-55 rounded-md'></div>

                                                    <div className='flex gap-3'>
                                                        <div>
                                                            <p className=' font-thin text-sm text-white-dark text-center'>Status:</p>
                                                            <p className='font-semibold text-[14px] text-warning text-center mt-[2px]'>{data.status}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-10 mt-5'>
                            <div className='bg-black px-5 rounded-lg pt-5'>
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <p className='font-bold text-info text-lg'>{ } Cluster Information</p>
                                        <p className='mt-1'>
                                            Last Updated: <span className='text-info'>{formattedDate}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='flex justify-between w-full mt-3 mb-10'>
                                    <div className='py-3'>
                                        <p className='font-medium text-[16px] text-success'>Positive Contributing Factors</p>
                                        <ul className='list-disc list-inside mt-2 text-[14px] pl-5'>
                                            {positiveFactors.map((factor, index) => (
                                                <li key={index}>{factor}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='mt-1'>
                                        <div className='flex gap-3 flex-col items-center justify-center'>
                                            <p className='text-[18px] font-semibold text-white-light'>Cluster</p>
                                            <p className='w-20 h-20 rounded-full border border-info text-lg font-bold text-info flex justify-center items-center text-center'>{getCreditScoreCluster(
                                                customerData.mpesaStatementData.
                                                    finalScore
                                            )}</p>
                                        </div>
                                    </div>
                                    <div className='py-3'>
                                        <p className='text-danger font-medium text-[16px]'>Negative Contributing Factors</p>
                                        <ul className='list-disc list-inside pl-5 mt-2 text-[14px]'>
                                            {negativeFactors.map((factor, index) => (
                                                <li key={index}>{factor}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='panel flex flex-col gap-5 text-gray-200'>
                                <h1 className='text-lg text-info'>CRB statement Data</h1>
                                <div className=' p-1 rounded-md'>
                                    <p className='text-warning/90'>Average Score</p>
                                    <p>{customerData.crbStatementData.
                                        averageScore}</p>
                                </div>
                                <div className='p-1 rounded-md'>
                                    <p className='text-warning/90'>BadAccount Score</p>
                                    <p>{customerData.crbStatementData.
                                        badaccountScore}</p>
                                </div>

                                <div className='p-1 rounded-md'>
                                    <p className='text-warning/90'>LatePay Score</p>
                                    <p>{customerData.crbStatementData.
                                        latepayScore}</p>
                                </div>
                            </div>
                            {/* <div className='bg-black rounded-lg'>
                    <ReactApexChart series={salesByCategory.series} options={salesByCategory.options} type="donut" height={380} />
                </div> */}

                        </div>
                    </div>
                ) : (
                        <p className='text-info text-lg'>loading data ...</p>
                )
            }
        </>

    );
};

export default ProfileBreakdownPage;
